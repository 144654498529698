import styled from 'styled-components';

export const DivSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 15px 0px;
`;

export const DivRange = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  margin: 0;
`;

export const DivInputLine = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0px 0px 20px 0px;
  gap: 5px; 
`;

export const DivInput = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  .dbBCSy{
    width: 120px;
  }
`;

export const DivRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-width: 289px;
`;

export const Row = styled.div`
  width: 40%;
  border: 1px solid #025CB7;
  @media screen and (max-width: 770px) {
    width: 70vw;
  }
`;


export const Title = styled.div`
  width: auto;
  font-size: 12px;
  margin-top: 10px;
  margin-Bottom: 5px;
  flex: left;
  font-weight: 600;
  font-family: Roboto, sans-serif;
  @media screen and (max-width: 700px) {
    width: 100vw;
  }
`;
