import React, { useState } from 'react';
import NavLinks from './NavLinks';
import { Nav, LeftArrow, Image, ButtonHidden } from './styles';
import Logo from '../../../assets/image/logo.svg';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import JoyRide from '../../JoyRide';
import { CacheTutorial } from '../../../utils/Constants/joyrideSteps';

type NavbarProps = {
  handleHideNavbar: () => void;
};

const NavBar = (props: NavbarProps) => {
  const [backOffNav, setbackOffNav] = useState(false);
  const intl = useIntl();
  const navigate = useNavigate();
  const slideHandler = () => {
    setbackOffNav((prevState) => !prevState);
  };

  const clearCacheAndReload = () => {
    if ('caches' in window) {
      caches.keys().then((cacheNames) => {
        cacheNames.forEach((cacheName) => {
          caches.delete(cacheName);
        });
      });
    }

    if ('indexedDB' in window) {
      const DBDeleteRequest = indexedDB.deleteDatabase('iokPlat');

      DBDeleteRequest.onerror = function (event) {
      };

      DBDeleteRequest.onsuccess = function (event) {
      };
    }

    document.cookie?.split(';')?.forEach((cookie) => {
      document.cookie = cookie
        .replace(/^ +/, '')
        .replace(/=.*/, '=;expires=' + new Date(0).toUTCString() + ';path=/');
    });

    navigate('/login');
    window.location.reload();
  };

  return (
    <>
      <Nav id='joyride-navbar' className={`${backOffNav ? 'back-off' : ''}`}>
        <Image
          className={`${backOffNav ? 'back-off' : ''}`}
          src={Logo}
          alt='Logo Prática'
        />
        <NavLinks hidden={backOffNav} handleHideNavbar={props.handleHideNavbar} />
        <ButtonHidden onClick={slideHandler} className='button-hidden'>
          <LeftArrow className={`${backOffNav ? 'right' : 'left'}`} />
        </ButtonHidden>
        <div id='joyride-cache' className='cache' onClick={clearCacheAndReload}>{intl.formatMessage({ id: 'menus.Clique para atualizar' })}</div>
        <p onClick={clearCacheAndReload}>{intl.formatMessage({ id: 'menus.Versão' })}: 1.8.15</p>
      </Nav>

      <JoyRide steps={CacheTutorial} />

    </>
  );
};

export default NavBar;
