export function privacyPolicyByLangugue (languageCode: string) {
    let url;

    switch (languageCode) {
        case 'PT':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/PT/Politica_Privacidade_PLATAFORMA_IOK_Portugues.pdf';
            break;
        case 'EN':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/EN/Privacy_Policy_IOK_PLATFORM_English.pdf';
            break;
        case 'ES':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/ES/Politica_Privacidad_PLATAFORMA_IOK_Espanol.pdf';
            break;
        case 'PL':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/EN/Privacy_Policy_IOK_PLATFORM_English.pdf';
            break;
        case 'ZH':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/EN/Privacy_Policy_IOK_PLATFORM_English.pdf';
            break;
        case 'RU':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/EN/Privacy_Policy_IOK_PLATFORM_English.pdf';
            break;
        case 'DE':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/EN/Privacy_Policy_IOK_PLATFORM_English.pdf';
            break;
        case 'FR':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/FR/Politique_Confidentialite_PLATEFORME_IOK_Fran%C3%A7ais.pdf';
            break;
        case 'EL':
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/EN/Privacy_Policy_IOK_PLATFORM_English.pdf';
            break;
        default:
            url = 'https://iok-privacy-policy.s3.sa-east-1.amazonaws.com/PT/Politica_Privacidade_PLATAFORMA_IOK_Portugues.pdf';
    }

    return url;
}
