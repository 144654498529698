import axios, { AxiosRequestConfig } from 'axios'
import Company from '../models/Company';
import CookbookModel from '../models/cookbook';
import User, { CreateUserRequestType, CreateUserTypeRequestType, UpdateUserRequestType, UserRecoverMenuModel } from '../models/User';
import UserConfigs from '../models/UserConfigs';
import {
	Equipment,
	LoadEquipmentByCompanyData,
	CountEquipmentFilterType,
	UpdateEquipmentRequestType,
	EquipmentCleaningExecution
} from '../models/equipment';

import {
	AddStoreRequestType,
	AddStoreResponseType,
	LoadStoreByCompanyData,
	CountEquipmentData,
	UpdateStoreRequestType
} from '../models/Stores';
import { LoggedUser } from '../db/db';
import UserRelationshipTutorial from '../models/UserRelationshipTutorials';
import { RecipeExecution } from '../models/Recipe';
import { ErrorLogsModel } from '../models/ErrorLogs';
import { AddLocationRequestType, AddLocationResponseType, LoadLocationByCompanyData, UpdateLocationRequestType } from '../models/Location';
import RequestServiceModel from '../models/RequestService';

export const config: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	responseType: 'json',
	headers: {
		accept: 'application/json',
		'content-type': 'application/json',
	},
	timeout: 7200000,
	withCredentials: false,
	maxRedirects: 5,
	validateStatus: (status: number) => status >= 200 && status < 300,
	transformResponse: (response) => {
		if (response !== null && response.error) {
			console.error(response.error);
		}
		let resp;
		try {
			resp = JSON.parse(response);
		} catch {
			resp = response;
		}
		return resp;
	},
};

interface UserData {
	user: User
	configs: UserConfigs
	company: Company
}

/********************************************** USER && CONFIG *********************************************/

export const login = async (userInfo: any) => {
	config.url = 'login'
	config.method = 'post'
	config.data = userInfo
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const authenticationPassword = async (userInfo: any, accessToken: string) => {
	config.url = 'authenticationPassword'
	config.method = 'post'
	config.data = userInfo
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};
export const createDeveloperToken = async (userInfo: any) => {
	config.url = 'developerToken'
	config.method = 'post'
	config.data = userInfo
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const signup = async (content: any) => {
	config.url = 'signup'
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const signupRecoverUser = async (content: any) => {
	config.url = 'signup/recover'
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const loadUserByCorporateName = async (corporateName: string) => {
	config.url = `/load/user/${corporateName}`
	config.method = 'get'
	config.params = corporateName
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const resetPasswordEmail = async (content: any) => {
	config.url = 'resetPassword/sendEmail'
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const activateAccount = async (activateToken: any) => {
	config.url = 'activate'
	config.method = 'put'
	config.data = activateToken
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const recuseUser = async (content: any) => {
	config.url = `recuse/user/${content.id}/userName/${content.userName}/companyName/${content.companyName}/email/${content.email}`;
	config.method = 'delete';
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const recuseDeveloperToken = async (email: string) => {
	config.url = `resetDeveloperToken/${email}`;
	config.method = 'put';
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const recoverMenu = async (content: UserRecoverMenuModel) => {
	config.url = 'recover/menu'
	config.method = 'post'
	config.timeout = 2400000
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const checkIfUserExists = async (email: string) => {
	const filter = { where: { email: email } };
	config.url = `users?filter=${JSON.stringify(filter)}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const loadCpfcnpj = async (cpfcnpj: any) => {
	config.url = `company/load/cpfcnpj`
	config.method = 'get'
	config.params = cpfcnpj
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const getUserData = async (accessToken: string) => {
	config.url = 'loadUser'
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return await axios.request<UserData>(config)
};

export const loadUserByCompany = async (
	id: any,
	accessToken: string,
	pageNumber?: number, 
	limitResult?: number, 
	searchTerm?: string
) => {
	let url = `company/${id}/user`;
	const queryParams: Record<string, string> = {};
	if (pageNumber) {
		queryParams.pageNumber = String(pageNumber);
	};
	if (limitResult) {
		queryParams.limitResult = String(limitResult);
	};
	if (searchTerm) {
		queryParams.searchTerm = searchTerm;
	};

	const queryString = new URLSearchParams(queryParams).toString();
	if (queryString) {
		url += `?${queryString}`;
	};

	config.url = url
	config.method = 'get'
	config.params = id
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
};

export const loadCompanyById = async (id: any) => {
	config.url = `company/${id}`
	config.method = 'get'
	config.params = id
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
};

export const deleteUser = async (id: number, accessToken: string) => {
	config.url = `user/${id}`;
	config.method = 'delete';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<any>(config);
};

export const editAccount = async (content: any, accessToken: string) => {
	config.url = `editUser`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
}

export const editUserResetPasswordAccount = async (content: any) => {
	config.url = `editUser/resetPassword`
	config.method = 'put'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const updateConfigUser = async (content: any, accessToken: string) => {
	config.url = `configUser/${content.userConfigs.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const createUser = async (content: CreateUserRequestType, accessToken: string) => {
	config.url = 'user'
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const updateUser = async (content: UpdateUserRequestType, accessToken: string) => {
	config.url = `user/${content.user.id}`
	config.method = 'put'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const getPreviousUser = async (email: string) => {
	config.url = `loadUser/${email}`
	config.method = 'get'
	config.data = email
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const getTutorials = async (idUser: number) => {
	config.url = `tutorials/${idUser}`
	config.method = 'get'
	config.params = idUser
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const deleteTutorials = async (idUser: number, accessToken: string) => {
	config.url = `/deleteTutorials/${idUser}`;
	config.method = 'delete';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request(config);
};

export const postUserRelationshipTutorials = async (data: UserRelationshipTutorial) => {
	config.url = `userTutorial`
	config.method = 'post'
	config.data = data
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

/********************************************** EQUIPMENT *********************************************/

export const loadEquipByMenu = async (id: any, accessToken: string) => {
	config.url = `equipment/menuId/${id}`
	config.method = 'get'
	config.params = id
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadEquipmentByCompanyId = async (
	user: LoggedUser, 
	accessToken: string, 
	pageNumber?: number, 
	limitResult?: number, 
	searchTerm?: string
) => {
	let url = `user/${user.id}/privilege/${user.userTypeId}/equipment/${user.companyId}/company`;
	const queryParams: Record<string, string> = {};
	if (pageNumber) {
		queryParams.pageNumber = String(pageNumber);
	};
	if (limitResult) {
		queryParams.limitResult = String(limitResult);
	};
	if (searchTerm) {
		queryParams.searchTerm = searchTerm;
	};

	const queryString = new URLSearchParams(queryParams).toString();
	if (queryString) {
		url += `?${queryString}`;
	};

	config.url = url;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<LoadEquipmentByCompanyData[]>(config);
};
	

export const loadEquipmentSeriousErrors = async (accessToken: string) => {
	config.url = `equipment/seriousErrors/0`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any[]>(config)
}
export const loadEquipments = async (accessToken: string) => {
	config.url = `equipment/all`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any[]>(config)
}

export const loadCountEquipment = async (
	accessToken: string,
	fieldFilter?: CountEquipmentFilterType
) => {
	if (fieldFilter) {
		const key = Object.keys(fieldFilter)[0];
		config.url = `equipment/count?where[${key}]=${fieldFilter[key]}`;
	} else {
		config.url = 'equipment/count';
	}

	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<CountEquipmentData>(config);
};

export const createEquipment = async (
	data: any,
	accessToken: string
) => {
	config.url = '/linux-equipments/create-new-linux/DE@Prat1c@BR2021';
	config.method = 'post';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.data = data;
	return axios.request<Equipment>(config);
};

export const deleteEquipment = async (id: number, accessToken: string) => {
	config.url = `equipment/${id}`;
	config.method = 'delete';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<LoadEquipmentByCompanyData[]>(config);
};

export const updateEquipment = async (id: number, content: UpdateEquipmentRequestType, accessToken: string) => {
	config.url = `equipment/${id}`;
	config.method = 'put'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const loadEquipmentByCompanyIdRemoteAccess = async (companyIdRemoteAccess: number, accessToken: string) => {
	config.url = `equipment/companyIdRemoteAccess/${companyIdRemoteAccess}`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<LoadEquipmentByCompanyData[]>(config)
};

export const loadCleaningExecutionListByEquipmentId = async (equipmentId: any, accessToken: string) => {
	config.url = `cleaningExecution/equipmentId/${equipmentId}`
	config.method = 'get'
	config.params = equipmentId
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<EquipmentCleaningExecution[]>(config)
};

export const loadCleaningExecutionListByDates = async (equipmentId: any, startDate: string, endDate: string, accessToken: string) => {
	config.url = `/cleaningExecution/equipmentId/${equipmentId}/startDate/'${startDate}'/endDate/'${endDate}'`
	config.method = 'get'
	config.params = equipmentId
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<EquipmentCleaningExecution[]>(config)
};

export const loadEquipmentById = async (id: number | string, accessToken: string) => {
	config.url = `equipment/${id}`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<Equipment>(config)
};

export const loadEquipmentBySerialNumber = async (serialNUmber: string, accessToken: string) => {
	config.url = `equipment/serialNumber/${serialNUmber}`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
};

export const loadEquipmentType = async (accessToken: string) => {
	config.url = `equipmentType/0`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
};

/********************************************** END EQUIPMENT *********************************************/

// Menu

export const createMenu = async (content: any, accessToken: string) => {
	config.url = `menu`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const updateMenu = async (content: any, accessToken: string) => {
	config.url = `menu/${content.menu.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'content-type': 'application/json',
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteMenu = async (id: number, accessToken: string) => {
	config.url = `menu/${id}`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const loadMenuByCompanyId = async (idCompany: number, accessToken: string) => {
	config.url = `company/${idCompany}/menu`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadMenuByCompanyIdWithCursor = async (
	idCompany: number,
	accessToken: string,
	limitResult?: number,
	pageNumber?: number,
	searchTerm?: string
) => {
	let url = `company/${idCompany}/menu`;
	const queryParams: Record<string, string> = {};
	if (pageNumber) {
		queryParams.pageNumber = String(pageNumber);
	};
	if (limitResult) {
		queryParams.limitResult = String(limitResult);
	};
	if (searchTerm) {
		queryParams.searchTerm = searchTerm;
	};

	const queryString = new URLSearchParams(queryParams).toString();
	if (queryString) {
		url += `?${queryString}`;
	};

	config.url = url;
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadMenuTrashByCompanyId = async (idCompany: number, accessToken: string) => {
	config.url = `company/${idCompany}/menuTrash`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadCompanySummaryById = async (idCompany: number, accessToken: string) => {
	config.url = `company/${idCompany}/summary`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadMenuAll = async (id: number, accessToken: string) => {
	config.url = `menu/${id}`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

// Group

export const createGroup = async (content: any, accessToken: string) => {
	config.url = `group`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadGroups = async (id: any, accessToken: string) => {
	config.url = `menu/${id}/group`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const editGroup = async (content: any, accessToken: string) => {
	config.url = `group/${content.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteGroup = async (content: any, accessToken: string) => {
	config.url = `group/${content.id}`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

// Recipe

export const createRecipe = async (content: any, accessToken: string) => {
	config.url = `recipe`
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const loadRecipes = async (id: any, accessToken: string) => {
	config.url = `group/${id}/recipe`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadSimpleRecipes = async (id: any, accessToken: string) => {
	config.url = `menu/${id}/simpleRecipe`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const createSimpleRecipe = async (content: any, accessToken: string) => {
	config.url = `simpleRecipe`
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
}

export const updateSimpleRecipe = async (content: any, accessToken: string) => {
	config.url = `simpleRecipe/${content.recipe.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
}

export const editRecipe = async (content: any, accessToken: string) => {
	config.url = `recipe/${content.recipe.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
}

export const deleteRecipes = async (content: any, accessToken: string) => {
	config.url = `recipe/array`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadRecipeExecutionListByEquipmentId = async (equipmentId: any, accessToken: string) => {
	config.url = `/recipeExecutions/equipmentId/${equipmentId}`
	config.method = 'get'
	config.params = equipmentId
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<RecipeExecution[]>(config)
}

export const loadRecipeExecutionListByDates = async (equipmentId: any, startDate: string, endDate: string, accessToken: string) => {
	config.url = `/recipeExecutions/equipmentId/${equipmentId}/startDate/'${startDate}'/endDate/'${endDate}'`
	config.method = 'get'
	config.params = equipmentId
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<RecipeExecution[]>(config)
}

export const loadErrorLogsListByEquipmentId = async (equipmentId: any, accessToken: string) => {
	config.url = `/errorLogs/equipmentId/${equipmentId}`
	config.method = 'get'
	config.params = equipmentId
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<ErrorLogsModel[]>(config)
}

export const loadErrorLogsListByDates = async (equipmentId: any, startDate: string, endDate: string, accessToken: string) => {
	config.url = `/errorLogs/equipmentId/${equipmentId}/startDate/'${startDate}'/endDate/'${endDate}'`
	config.method = 'get'
	config.params = equipmentId
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<ErrorLogsModel[]>(config)
}


// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!

// Lojas

export const addNewStore = async (
	store: AddStoreRequestType,
	accessToken: string
) => {
	config.url = 'store';
	config.method = 'post';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.data = {
		store: {
			...store,
		},
	};
	return axios.request<AddStoreResponseType>(config);
};

export const deleteStore = async (id: number, accessToken: string) => {
	config.url = `store/${id}`;
	config.method = 'delete';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request(config);
};

export const loadStoreById = async (id: number, accessToken: string) => {
	config.url = `store/${id}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<any>(config);
};

export const loadStoresByCompanyId = async (
	user: LoggedUser,
	accessToken: string
) => {
	config.url = `user/${user.id}/privilege/${user.userTypeId}/company/${user.companyId}/stores`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<LoadStoreByCompanyData>(config);
};

export const updateStore = async (content: UpdateStoreRequestType, accessToken: string) => {
	config.url = `store/${content.store.id}`;
	config.method = 'put';
	config.data = content;
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request(config);
};

/* request que busca o CEP na API dos Correios */

export const getZipCodeInfo = async (zipCode: string) => {
	const configZipCode: AxiosRequestConfig = {
		baseURL: 'https://viacep.com.br/ws/',
		responseType: 'json',
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
		},
		timeout: 40000,
		withCredentials: false,
		maxRedirects: 5,
		validateStatus: (status: number) => status >= 200 && status < 300,
		transformResponse: (response) => {
			if (response !== null && response.error) {
				console.error(response.error);
			}
			const resp = JSON.parse(response);
			return resp;
		},
	};

	configZipCode.url = `${zipCode}/json/`;
	configZipCode.method = 'get';
	return axios.request(configZipCode);
};

export const getLatLngInfo = async (address: string) => {
	const configLatLng: AxiosRequestConfig = {
		baseURL: 'https://maps.googleapis.com/maps/api/geocode/',
		responseType: 'json',
		headers: {
			accept: 'application/json',
			'content-type': 'application/json',
		},
		timeout: 40000,
		withCredentials: false,
		maxRedirects: 5,
		validateStatus: (status: number) => status >= 200 && status < 300,
		transformResponse: (response) => {
			if (response !== null && response.error) {
				console.error(response.error);
			}
			const resp = JSON.parse(response);
			return resp;
		},
	};

	configLatLng.url = `json?address=${address}&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`;
	configLatLng.method = 'get';
	return axios.request(configLatLng);
};

// Cookbook

export const createCookbook = async (content: { cookbook: CookbookModel }, accessToken: string) => {
	config.url = `cookbook`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadCookbookRecipes = async (
	id: any,
	accessToken: string,
	pageNumber?: number,
	limitResult?: number,
	searchTerm?: string
) => {
	let url = `company/${id}/cookbook`;
	const queryParams: Record<string, string> = {};
	if (pageNumber) {
		queryParams.pageNumber = String(pageNumber);
	};
	if (limitResult) {
		queryParams.limitResult = String(limitResult);
	};
	if (searchTerm) {
		queryParams.searchTerm = searchTerm;
	};

	const queryString = new URLSearchParams(queryParams).toString();
	if (queryString) {
		url += `?${queryString}`;
	};
	config.url = url
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
};

export const editCookbook = async (content: any, accessToken: string) => {
	config.url = `cookbook/${content.cookbook.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteCookbook = async (content: any, accessToken: string) => {
	config.url = `cookbook/array`
	config.method = 'delete'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

// Steps Speed Oven

export const createStepSpeedOven = async (content: any, accessToken: string) => {
	config.url = `stepSpeedOven`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadStepsSpeedOven = async (id: any, stepFrom: string, accessToken: string) => {
	config.url = `/${stepFrom}/recipe/${id}/stepsSpeedOven`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const editStepSpeedOven = async (content: any, accessToken: string) => {
	config.url = `stepSpeedOven/${content.stepSpeedOven.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteStepSpeedOven = async (id: any, accessToken: string) => {
	config.url = `stepSpeedOven/${id}`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

// Steps Combi Oven TSi 

export const createStepCombiOvenTSI = async (content: any, accessToken: string) => {
	config.url = `stepCombiOvenTSI`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadStepsCombiOvenTSI = async (id: any, stepFrom: string, accessToken: string) => {
	config.url = `/${stepFrom}/recipe/${id}/stepsCombiOvenTSI`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const updateStepCombiOvenTSI = async (content: any, accessToken: string) => {
	config.url = `stepCombiOvenTSI/${content.stepCombiOvenTSI.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteStepCombiOvenTSI = async (id: any, accessToken: string) => {
	config.url = `stepCombiOvenTSI/${id}`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

// Steps Combi Oven CMAX

export const createStepCombiOvenCMAX = async (content: any, accessToken: string) => {
	config.url = `stepCombiOvenCMAX`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadStepsCombiOvenCMAX = async (id: any, stepFrom: string, accessToken: string) => {
	config.url = `${stepFrom}/recipe/${id}/stepsCombiOvenCMAX`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const updateStepCombiOvenCMAX = async (content: any, accessToken: string) => {
	config.url = `stepCombiOvenCMAX/${content.stepCombiOvenCMAX.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteStepCombiOvenCMAX = async (id: any, accessToken: string) => {
	config.url = `stepCombiOvenCMAX/${id}`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

// Atualização de software 

export const downloadUpdate = async (ovenType: string, accessToken: string) => {
	config.url = `/download/platform/updateFile/${ovenType}`
	config.method = 'get'
	config.responseType = 'blob'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return { downloadFile: axios.request<any>(config), source: axios.CancelToken.source() };
};

export const loadCompanyTypes = async () => {
	config.url = `/companyTypes`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadCompaniesDistribuidorPratica = async (accessToken: string) => {
	config.url = `/company/companyType/0`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadPermissionByCompanyType = async (companyTypeId: string, userTypeId: number, accessToken: string) => {
	config.url = `permissionTypes/companyTypeId/"${companyTypeId}"/userTypeId/${userTypeId}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<any>(config);
};

export const createUserType = async (content: CreateUserTypeRequestType, accessToken: string) => {
	config.url = 'userTypes'
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const loadUserTypesByCompany = async (companyId: number, accessToken: string) => {
	config.url = `userTypes/companyId/${companyId}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<any>(config);
};

/********************************************** EXPORT MENU *********************************************/

export const exportSpeedOvens = async (equipmentModel: string, menuId: number, accessToken: string) => {
	config.url = `speed-ovens-file/export/${equipmentModel}/${menuId}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.responseType = 'blob';
	var resp = axios.request(config);
	config.responseType = 'json';
	return resp;
};

export const exportTsi = async (menuId: number, accessToken: string) => {
	config.url = `tsi-file/export/${menuId}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.responseType = 'blob';
	var resp = axios.request(config);
	config.responseType = 'json';
	return resp;
};

export const exportCMax = async (menuId: number, equipType: number, accessToken: string) => {
	config.url = `c-max-file/export/${menuId}/equipType/${equipType}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.responseType = 'blob';
	var resp = axios.request(config);
	config.responseType = 'json';
	return resp;
};

export const exportConvection = async (menuId: number, temperatureUnit: string, accessToken: string) => {
	config.url = `/convection-file/export/${menuId}/temperatureUnit/${temperatureUnit}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.responseType = 'blob';
	var resp = axios.request(config);
	config.responseType = 'json';
	return resp;
};

export const exportLastroToro = async (menuId: number, accessToken: string) => {
	config.url = `/lastro-toro-file/export/${menuId}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.responseType = 'blob';
	var resp = axios.request(config);
	config.responseType = 'json';
	return resp;
};

export const exportForza = async (menuId: number, accessToken: string) => {
	config.url = `forza-file/export/${menuId}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.responseType = 'blob';
	var resp = axios.request(config);
	config.responseType = 'json';
	return resp;
};

export const exportSpeedOvensLegacy = async (generation: string, menuId: number, accessToken: string) => {
	config.url = `speed-ovens-legacy/export/${generation}/${menuId}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.responseType = 'blob';
	var resp = axios.request(config);
	config.responseType = 'json';
	return resp;
};

export const getMetabaseUrl = async (idDashboard: number, accessToken: string) => {
	config.url = `get-metabase-url/${idDashboard}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<any>(config);
};

export const loadAdmByEquipmentType = async (equipmentType: string, accessToken: string) => {
	config.url = `load/user/equipmentType/${equipmentType}`
	config.method = 'get'
	config.params = equipmentType
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
};

export const addSoftwareVersion = async (
	formData: any,
	accessToken: string
) => {
	config.url = `add-software-version`;
	config.method = 'post';
	config.data = formData;
	// config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			// 'accept': 'application/json',
			// 'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

/************************************* IMPORT MENU ******************************************/

export const importSpeedOvens = async (
	formData: FormData,
	accessToken: string
) => {
	config.url = `speed-ovens-file/import`;
	config.method = 'post';
	config.data = formData;
	config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'accept': 'application/json',
			'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

export const importTSi = async (
	formData: FormData,
	accessToken: string
) => {
	config.url = `tsi-file/import`;
	config.method = 'post';
	config.data = formData;
	config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'accept': 'application/json',
			'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

export const importCMAX = async (
	formData: FormData,
	accessToken: string
) => {
	config.url = `c-max-file/import`;
	config.method = 'post';
	config.data = formData;
	config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'accept': 'application/json',
			'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

export const importLastroToro = async (
	formData: FormData,
	accessToken: string
) => {
	config.url = `lastro-toro-file/import`;
	config.method = 'post';
	config.data = formData;
	config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'accept': 'application/json',
			'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

export const importForza = async (
	formData: FormData,
	accessToken: string
) => {
	config.url = `forza-legacy-file/import`;
	config.method = 'post';
	config.data = formData;
	config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'accept': 'application/json',
			'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

export const importSpeedOvensLegacy = async (
	formData: FormData,
	accessToken: string
) => {
	config.url = `copa-legacy-file/import`;
	config.method = 'post';
	config.data = formData;
	config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'accept': 'application/json',
			'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

export const importConvectionOven = async (
	formData: FormData,
	accessToken: string
) => {
	config.url = `convection-file/import`;
	config.method = 'post';
	config.data = formData;
	config.responseType = 'text' as 'json';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			'accept': 'application/json',
			'content-type': 'multipart/form-data',
		});
	}
	return await axios.request(config);
};

export const loadSoftwareVersion = async (versionType: string, accessToken: string) => {
	config.url = `softwareVersion/'${versionType}'`
	config.method = 'get'
	config.params = versionType
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
};

export const sendEmailMergeAccounts = async (content: any, accessToken: string) => {
	config.url = 'sendEmail/company/merge'
	config.method = 'post'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

export const updateMergeAccounts = async (content: any) => {
	config.url = 'company/merge'
	config.method = 'put'
	config.data = content
	if (config.headers) {
		Object.assign(config.headers, {
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

/************************************* STEP LASTRO TORO ******************************************/

export const createStepLastroToro = async (content: any, accessToken: string) => {
	config.url = `stepLastroToro`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadByIdStepsLastroToro = async (id: any, accessToken: string) => {
	config.url = `stepLastroToro/${id}`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadStepsLastroToro = async (recipeId: any, stepFrom: string, accessToken: string) => {
	config.url = `${stepFrom}/recipe/${recipeId}/stepsLastroToro`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const updateStepLastroToro = async (content: any, accessToken: string) => {
	config.url = `stepLastroToro/${content.stepLastroToro.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteStepLastroToro = async (id: any, accessToken: string) => {
	config.url = `stepLastroToro/${id}`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

/************************************* STEP CONVECCTION OVENS ******************************************/

export const createStepConvectionOven = async (content: any, accessToken: string) => {
	config.url = `stepConvectionOven`
	config.method = 'post'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const loadByIdStepsConvecctionOven = async (id: any, accessToken: string) => {
	config.url = `stepConvecctionOven/${id}`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const loadStepsConvectionOven = async (recipeId: any, stepFrom: string, accessToken: string) => {
	config.url = `${stepFrom}/recipe/${recipeId}/stepsConvectionOven`
	config.method = 'get'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config)
}

export const updateStepConvectionOven = async (content: any, accessToken: string) => {
	config.url = `stepConvectionOven/${content.stepConvectionOven.id}`
	config.method = 'put'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	config.data = content
	return axios.request<any>(config);
};

export const deleteStepConvectionOven = async (id: any, accessToken: string) => {
	config.url = `stepConvectionOven/${id}`
	config.method = 'delete'
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		})
	}
	return axios.request<any>(config);
};

// Localização

export const addLocation = async (
	location: AddLocationRequestType,
	accessToken: string
) => {
	config.url = 'location';
	config.method = 'post';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	config.data = {
		location: {
			...location,
		},
	};
	return axios.request<AddLocationResponseType>(config);
};

export const deleteLocation = async (id: number, accessToken: string) => {
	config.url = `location/${id}`;
	config.method = 'delete';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request(config);
};

export const loadLocationById = async (id: number, accessToken: string) => {
	config.url = `location/${id}`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<any>(config);
};

export const loadLocationsByCompanyId = async (
	user: LoggedUser,
	accessToken: string
) => {
	config.url = `company/${user.companyId}/locations`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<LoadLocationByCompanyData>(config);
};

export const updateLocation = async (content: UpdateLocationRequestType, accessToken: string) => {
	config.url = `location/${content.location.id}`;
	config.method = 'put';
	config.data = content;
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request(config);
};

export const requestService = async (
	content: RequestServiceModel,
	accessToken: string
) => {
	config.url = `requestService`;
	config.method = 'post';
	config.data = content;
	if (config.headers) {
		Object.assign(config.headers, {
			'x-access-token': accessToken,
		});
	}
	return await axios.request(config);
};

export const loadEquipmentSold = async () => {
	config.baseURL = 'https://testeprp.praticabr.com:3335/api/'
	config.url = `Sb1010s/getTopModels?models=
	001035,
	001096,
	001143,
	001146,
	001041,
	001042,
	001043,
	001044,
	001045,
	001046,
	001047,
	001048,
	001049,
	001050,
	001126,
	001247,
	000948,
	001012,
	001013,`;
	config.method = 'get';
	if (config.headers) {
		Object.assign(config.headers, {
			'apiKey': 'Pr@t1çaPr0dut0s2025',
			accept: 'application/json',
			'content-type': 'application/json'
		});
	}
	return axios.request<any>(config);
};