import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import Switch from '../../FormElements/Switch';
import PrimaryButton from './../../FormElements/PrimaryButton/index';
import Modal from '../../UIelements/Modal';
import * as Styled from './styles';
import { db } from '../../../db/db'
import { useLiveQuery } from 'dexie-react-hooks'
import { toast } from 'react-toastify';
import { useForm } from '../../../hooks/form-hook';
import { updateConfigUser } from '../../../services';
import InputChecked from '../../FormElements/InputChecked';

type ModalProps = {
  onCancel: () => void;
  userConfigsDb: any;
}

const initialValue = {
  value: '',
  isValid: false,
};

const ModalConfigs: React.FC<ModalProps> = (props) => {
  const intl = useIntl();
  const userDb = useLiveQuery(() => db.user.toArray())
  const id: number = (props.userConfigsDb.id ? props.userConfigsDb.id : 0);
  const [themeChecked, setThemeChecked] = useState<boolean>(props.userConfigsDb.theme === 'Dark' ? true : false);

  const [formState, inputHandler] = useForm(
    {
      theme: initialValue,
      tempUnit: initialValue,
      weightUnit: initialValue
    },
    false
  );

  const submitHandler = () => {
    try {
      const submit = {
        userConfigs: {
          id: props.userConfigsDb && props.userConfigsDb.id,
          userId: props.userConfigsDb && props.userConfigsDb.userId,
          tempUnit: formState.inputs.tempUnit.value,
          weightUnit: formState.inputs.weightUnit.value,
          theme: formState.inputs.theme.value
        }
      }
      updateConfigUser(submit, userDb ? userDb[0].accessToken : '')
        .then(resp => {
          db.userConfigs.update(id, submit.userConfigs)
          setTimeout(function () {
            toast.success(intl.formatMessage({ id: 'main.Configurações salvas com sucesso!' }).toUpperCase());
            props.onCancel();
          }, 250);
        })
        .catch(error => {
          console.error(error);
          toast.error(intl.formatMessage({ id: 'menus.Existem campos com informações inválidas' }).toUpperCase());
        })
    } catch (error) {
      console.error(error);
    }
  }

  const footer = (
    <>
      <PrimaryButton type='button' size='buttonModal' model='confirmation-gradient-modal' width='108px' onClick={() => submitHandler()}>
        {intl.formatMessage({ id: 'main.SALVAR' })}
      </PrimaryButton>
      <PrimaryButton type='button' size='buttonModal' model='cancellation' width='108px' onClick={() => props.onCancel()} >
        {intl.formatMessage({ id: 'menus.CANCELAR' })}
      </PrimaryButton>
    </>
  )

  return (
    <>
      <Modal
        title={intl.formatMessage({ id: 'main.CONFIGURAÇÕES' })}
        onCancel={props.onCancel}
        onSubmit={submitHandler}
        footer={footer}
        width=''
      >
        <Styled.DivSwitch>
          <Styled.Title>{intl.formatMessage({ id: 'main.Tema dark' })}</Styled.Title>
          <Switch id={'theme'} valueTrue={'Dark'} valueFalse={'Light'} onChange={() => setThemeChecked(!themeChecked)} checked={themeChecked} onInput={inputHandler}></Switch>
        </Styled.DivSwitch>

        <Styled.Title>
          <Styled.DivRow>
            {intl.formatMessage({ id: 'main.Informações para as receitas' })}
            <Styled.Row />
          </Styled.DivRow>
        </Styled.Title>

        <Styled.Title>{intl.formatMessage({ id: 'main.Peso:' })}</Styled.Title>
        <Styled.DivInput>
          <InputChecked id='weightUnit' type={'radio'} label={'main.Gramas'} name={'weight'} value={'Gramas'} default={props.userConfigsDb && props.userConfigsDb.weightUnit === 'Gramas' ? true : false} initialValue={props.userConfigsDb.weightUnit} initialValid onInput={inputHandler} labelName={'Gramas'} />
          <InputChecked id='weightUnit' type={'radio'} label={'main.Oz'} name={'weight'} value={'Oz'} default={props.userConfigsDb && props.userConfigsDb.weightUnit === 'Oz' ? true : false} initialValue={props.userConfigsDb.weightUnit} initialValid onInput={inputHandler} labelName={'Oz'} />
        </Styled.DivInput>

        <Styled.Title>{intl.formatMessage({ id: 'main.Temperatura:' })}</Styled.Title>
        <Styled.DivInput>
          <InputChecked id='tempUnit' type={'radio'} label={'Celsius (°C)'} name={'temp'} value={'°C'} default={props.userConfigsDb && props.userConfigsDb.tempUnit === "°C" ? true : false} initialValue={props.userConfigsDb.tempUnit} initialValid onInput={inputHandler} labelName={'°C'} />
          <InputChecked id='tempUnit' type={'radio'} label={'Fahrenheit (°F)'} name={'temp'} value={'°F'} default={props.userConfigsDb && props.userConfigsDb.tempUnit === "°F" ? true : false} initialValue={props.userConfigsDb.tempUnit} initialValid onInput={inputHandler} labelName={'°F'} />
        </Styled.DivInput>
      </Modal>
    </>
  );
};

export default ModalConfigs;
