// src/components/JoyRide.tsx
import React, { useEffect, useState } from 'react';
import ReactJoyride, { CallBackProps } from 'react-joyride';
import { postUserRelationshipTutorials } from '../../services';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../db/db';
import { useIntl } from 'react-intl';
import { useSelector, useDispatch } from 'react-redux';
import { setRunTutorials } from '../../store/tutorialsSlice';

const JoyRide: React.FC<{ steps: any, run?: boolean }> = ({ steps, run }) => {
  const userConfigDb = useLiveQuery(() => db.userConfigs.toArray());
  const [isRunning, setIsRunning] = useState<boolean | undefined>(run ?? false);
  const [initialStepIndex, setInitialStepIndex] = useState<number | undefined>(run ? 0 : undefined);
  const intl = useIntl();
  const dispatch = useDispatch();
  const runTutorials = useSelector((state: any) => state.tutorials.runTutorials)

  useEffect(() => {
    if (run !== undefined) {
      setIsRunning(run);
      if (run) {
        setInitialStepIndex(0);
      }
    } else {
      setIsRunning(true);
      setInitialStepIndex(undefined);
    }
  }, [run, runTutorials]);

  const handleJoyrideCallback = async (e: CallBackProps) => {
    if (
      (e.action === 'next' && e.status === 'finished' && e.type === 'tour:end') ||
      (e.action === 'skip' && e.status === 'skipped' && e.type === 'tour:end')
    ) {
      setIsRunning(false);
      if (userConfigDb && userConfigDb[0].userId) {
        const request = {
          userTutorial: {
            userId: userConfigDb[0].userId,
            tutorialId: steps.id,
          },
        };
        postUserRelationshipTutorials(request)
          .then((resp) => {
            if (userConfigDb && userConfigDb[0].tutorialsUnmade && userConfigDb[0].id) {
              const submit = {
                userConfigs: {
                  id: userConfigDb[0].id,
                  tutorialsUnmade: userConfigDb[0].tutorialsUnmade.filter((tutorial) => tutorial.id !== steps.id),
                },
              };
              db.userConfigs.update(submit.userConfigs.id, submit.userConfigs);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
      dispatch(setRunTutorials(false));
    }
  };

  return (
    window.innerWidth > 900 &&
    userConfigDb &&
    userConfigDb[0].tutorialsUnmade?.find((f) => f.id === steps.id) ? (
      <ReactJoyride
        continuous
        hideCloseButton
        scrollToFirstStep
        showProgress
        showSkipButton
        run={isRunning}
        stepIndex={initialStepIndex}
        steps={steps.step}
        callback={handleJoyrideCallback}
        locale={{
          back: intl.formatMessage({ id: 'menus.VOLTAR' }),
          close: intl.formatMessage({ id: 'tutorial.Fechar' }),
          last: intl.formatMessage({ id: 'tutorial.Fim' }),
          next: intl.formatMessage({ id: 'tutorial.Próximo' }),
          open: intl.formatMessage({ id: 'tutorial.Abrir' }),
          skip: intl.formatMessage({ id: 'tutorial.Pular tutorial' }),
        }}
        styles={{
          options: {
            arrowColor: '#fff',
            backgroundColor: '#fff',
            beaconSize: 46,
            overlayColor: 'rgba(0, 0, 0, 0.5)',
            primaryColor: '#5DAEFF',
            spotlightShadow: '0 0 15px rgba(0, 0, 0, 0.5)',
            textColor: '#333',
            width: undefined,
            zIndex: 200,
          },
        }}
      />
    ) : null
  );
};

export default JoyRide;
