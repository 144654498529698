import { configureStore } from '@reduxjs/toolkit';
import equipmentsReducer from './equipmentSlice';
import equipmentsMasterReducer from './equipmentsMasterSlice';
import menusReducer from './menusSlice';
import scrollReducer from './scrollSlice';
import tutorialsReducer from './tutorialsSlice';

const store = configureStore({
  reducer: {
    equipments: equipmentsReducer,
    equipmentsMaster: equipmentsMasterReducer,
    menus: menusReducer,
    scroll: scrollReducer,
    tutorials: tutorialsReducer
  }
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;