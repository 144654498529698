import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import PrimaryButton from './../../FormElements/PrimaryButton/index';
import Modal from '../../UIelements/Modal';
import * as Styled from './styles';
import { authenticationPassword, editAccount, createDeveloperToken } from '../../../services';
import { toast } from 'react-toastify';
import InputForm from '../../FormElements/InputForm';
import { VALIDATOR_EMAIL, VALIDATOR_REQUIRE, VALIDATOR_MINLENGTH } from '../../../utils/validators';
import { useForm } from '../../../hooks/form-hook';
import { useLiveQuery } from 'dexie-react-hooks';
import { db } from '../../../db/db';
import { IoIosLock } from 'react-icons/io';
import { IoMdCreate } from 'react-icons/io';
import { BsFileEarmarkCode } from 'react-icons/bs';
import { FiCopy } from "react-icons/fi";
import PhoneInput from 'react-phone-number-input';
import Loading from '../../Loading';

type ModalProps = {
  user: any;
  onCancel: () => void;
}

const ModalEditAccount: React.FC<ModalProps> = (props) => {
  const intl = useIntl();
  const [pageModal, setPageModal] = useState<string>("EditAccount");
  const userDb = useLiveQuery(() => db.user.toArray());
  const [emailFieldEnabled, setEmailFieldEnabled] = useState<boolean>(false);
  const [phone, setPhone] = useState<any>(props.user.phone);
  const [isLoading, setIsLoading] = useState(false);
  const { innerWidth } = window;
  const [tokenAccess, setTokenAccess] = useState<{
    isActive: boolean,
    token?: string
  }>({
    isActive: !!props.user.developerToken,
    token: props.user.developerToken
  });

  useEffect(() => {
    if (phone && !phone.startsWith('+')) {
      setPhone('+' + phone);
    }
  }, [phone]);

  const [formState, inputHandler] = useForm(
    {
      userName: {
        value: '',
        isValid: false,
      },
      email: {
        value: '',
        isValid: false,
      },
      password: {
        value: '',
        isValid: true,
      },
      passwordConfirm: {
        value: '',
        isValid: true,
      },
      passwordCurrent: {
        value: '',
        isValid: true,
      }
    },
    false
  );

  const submitHandler = () => {
    if (formState.isValid === false) {
      toast.error(intl.formatMessage({ id: 'main.Existem campos que não foram preenchidos corretamente' }).toUpperCase());
      return;
    }
    try {
      const submit = {
        user: {
          id: userDb![0].id,
          userName: formState.inputs.userName.value,
          email: formState.inputs.email.value,
          phone: phone
        }
      }
      if (submit && userDb) {
        editAccount(submit, userDb[0].accessToken)
          .then(resp => {
            setTimeout(function () {
              db.user.update(props.user.id, resp.data.editedUserData)
              toast.success(intl.formatMessage({ id: 'main.Conta editada com sucesso!' }).toUpperCase());
              props.onCancel();
            }, 250);
            setIsLoading(false)
          })
          .catch(error => {
            console.error(error);
            toast.error(intl.formatMessage({ id: 'menus.Existem campos com informações inválidas' }).toUpperCase());
            setIsLoading(false)
          })
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'menus.Existem campos com informações inválidas' }).toUpperCase());
      setIsLoading(false)
    }
  }

  const submitInformPassword = () => {
    if (formState.inputs.passwordCurrent.value === '') {
      toast.error(intl.formatMessage({ id: 'main.Existem campos que não foram preenchidos corretamente' }).toUpperCase());
      setIsLoading(false)
      return;
    }
    try {
      const userInfo = {
        email: formState.inputs.email.value,
        password: formState.inputs.passwordCurrent.value
      }
      if (userDb) {
        authenticationPassword(userInfo, userDb[0].accessToken)
          .then(resp => {
            setEmailFieldEnabled(true);
            setPageModal("EditAccount");
            setIsLoading(false)
          })
          .catch(error => {
            console.error(error);
            toast.error(intl.formatMessage({ id: 'main.Informe sua senha atual corretamente' }).toUpperCase());
            setIsLoading(false)
          })
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'menus.Existem campos com informações inválidas' }).toUpperCase());
      setIsLoading(false)
    }
  }

  const submitChangePassword = () => {
    if (formState.inputs.passwordCurrent.value === '') {
      toast.error(intl.formatMessage({ id: 'main.Existem campos que não foram preenchidos corretamente' }).toUpperCase());
      setIsLoading(false)
      return;
    }
    if (formState.inputs.password.value !== formState.inputs.passwordConfirm.value) {
      toast.error(intl.formatMessage({ id: 'register.As senhas devem ser iguais' }).toUpperCase());
      setIsLoading(false)
      return;
    }
    try {
      const submit = {
        user: {
          id: userDb![0].id,
          userName: formState.inputs.userName.value,
          email: formState.inputs.email.value,
          emailVerified: userDb![0].emailVerified,
          password: formState.inputs.password.value,
          companyId: userDb![0].companyId,
          phone: phone,
          userTypeId: userDb![0].userTypeId
        }
      }
      const userInfo = {
        email: formState.inputs.email.value,
        password: formState.inputs.passwordCurrent.value
      }
      if (userDb) {
        authenticationPassword(userInfo, userDb[0].accessToken)
          .then(resp => {
            editAccount(submit, userDb[0].accessToken)
              .then(resp => {
                setTimeout(function () {
                  toast.success(intl.formatMessage({ id: 'main.Conta editada com sucesso!' }).toUpperCase());
                  db.user.update(props.user.id, resp.data.editedUserData)
                  props.onCancel();
                }, 250);
                setIsLoading(false);
              })
              .catch(error => {
                console.error(error);
                toast.error(intl.formatMessage({ id: 'menus.Existem campos com informações inválidas' }).toUpperCase());
                setIsLoading(false);
              })
          })
          .catch(error => {
            console.error(error);
            toast.error(intl.formatMessage({ id: 'main.Informe sua senha atual corretamente' }).toUpperCase());
            setIsLoading(false)
          })
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'menus.Existem campos com informações inválidas' }).toUpperCase());
      setIsLoading(false)
    }
  }

  const submitDeveloperToken = () => {
    if (formState.inputs.passwordCurrent.value === '') {
      toast.error(intl.formatMessage({ id: 'main.Existem campos que não foram preenchidos corretamente' }).toUpperCase());
      setIsLoading(false)
      return;
    }
    try {
      const userInfo = {
        email: formState.inputs.email.value,
        password: formState.inputs.passwordCurrent.value
      }
      if (userDb) {
        authenticationPassword(userInfo, userDb[0].accessToken)
          .then(resp => {
            createDeveloperToken({ id: props.user.id, email: userInfo.email })
              .then(resp => {
                const developerToken = resp.data.developerToken
                setTokenAccess({
                  isActive: true,
                  token: developerToken
                })
                db.user.update(props.user.id, { id: props.user.id, developerToken })
                setIsLoading(false)
              })
          })
          .catch(error => {
            console.error(error);
            toast.error(intl.formatMessage({ id: 'main.Informe sua senha atual corretamente' }).toUpperCase());
            setIsLoading(false)
          })
      }
    } catch (error) {
      toast.error(intl.formatMessage({ id: 'menus.Existem campos com informações inválidas' }).toUpperCase());
      setIsLoading(false)
    }
  }

  const handleCopyClick = () => {
    if (tokenAccess.token) {
      if (!navigator.clipboard) {
        const textarea = document.createElement('textarea');
        textarea.value = tokenAccess.token;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        toast.success(intl.formatMessage({ id: 'main.Token copiado com sucesso!' }).toUpperCase());
      } else {
        navigator.clipboard.writeText(tokenAccess.token)
          .then(() => {
            toast.success(intl.formatMessage({ id: 'main.Token copiado com sucesso!' }).toUpperCase())
          })
          .catch((error) => {
            console.error(error);
          })
      }
    }
  };

  const footer = (
    <>
      <PrimaryButton type='button' size='buttonModal' model='confirmation-gradient-modal' width='108px' onClick={() => (setIsLoading(true), submitHandler())}>
        {intl.formatMessage({ id: 'main.SALVAR' })}
      </PrimaryButton>
      <PrimaryButton type='button' size='buttonModal' model='cancellation' width='108px' onClick={() => props.onCancel()} >
        {intl.formatMessage({ id: 'menus.CANCELAR' })}
      </PrimaryButton>
    </>
  );

  const footerChangePassword = (
    <>
      <PrimaryButton type='button' size='buttonModal' model='confirmation-gradient-modal' width='108px' onClick={() => (setIsLoading(true), submitChangePassword())}>
        {intl.formatMessage({ id: 'main.SALVAR' })}
      </PrimaryButton>
      <PrimaryButton type='button' size='buttonModal' model='cancellation' width='108px' onClick={() => setPageModal("EditAccount")} >
        {intl.formatMessage({ id: 'menus.CANCELAR' })}
      </PrimaryButton>
    </>
  );

  const footerInformPassword = (
    <>
      <PrimaryButton type='button' size='buttonModal' model='confirmation-gradient-modal' width='108px' onClick={() => (setIsLoading(true), submitInformPassword())}>
        {intl.formatMessage({ id: 'main.CONFIRMAR' })}
      </PrimaryButton>
      <PrimaryButton type='button' size='buttonModal' model='cancellation' width='108px' onClick={() => setPageModal("EditAccount")} >
        {intl.formatMessage({ id: 'menus.CANCELAR' })}
      </PrimaryButton>
    </>
  );

  const footerDeveloperToken = (
    <>
      <PrimaryButton type='button' size='buttonModal' model='confirmation-gradient-modal' width='108px' onClick={() => (setIsLoading(true), submitDeveloperToken())}>
        {intl.formatMessage({ id: 'main.CONFIRMAR' })}
      </PrimaryButton>
      <PrimaryButton type='button' size='buttonModal' model='cancellation' width='108px' onClick={() => setPageModal("EditAccount")} >
        {intl.formatMessage({ id: 'menus.CANCELAR' })}
      </PrimaryButton>
    </>
  );

  const footerTokenActive = (
    <>
      <PrimaryButton type='button' size='large' model='cancellation' width='108px' onClick={() => setPageModal("EditAccount")} >
        {intl.formatMessage({ id: 'tutorial.Fechar' })}
      </PrimaryButton>
    </>
  );

  const ChangePassword = (
    <>
      <Modal
        title={intl.formatMessage({ id: 'main.ALTERAR SENHA' })}
        onCancel={props.onCancel}
        onSubmit={submitHandler}
        footer={footerChangePassword}
        width='380px'
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Styled.DivText>
              <Styled.Title>{intl.formatMessage({ id: 'main.Senha atual' })}</Styled.Title>
              <InputForm
                id='passwordCurrent'
                type='password'
                placeholder={intl.formatMessage({ id: 'main.Informe sua senha atual' })}
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText={intl.formatMessage({ id: 'main.O campo senha deve conter no mínimo 6 caracteres' })}
                onInput={inputHandler}
              />
            </Styled.DivText>

            <Styled.DivText>
              <Styled.Title>{intl.formatMessage({ id: 'main.Nova senha' })}</Styled.Title>
              <InputForm
                id='password'
                type='password'
                placeholder={intl.formatMessage({ id: 'main.Informe sua nova senha' })}
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText={intl.formatMessage({ id: 'main.O campo senha deve conter no mínimo 6 caracteres' })}
                onInput={inputHandler}
              />
            </Styled.DivText>

            <Styled.DivText>
              <Styled.Title>{intl.formatMessage({ id: 'main.Confirmar nova senha' })}</Styled.Title>
              <InputForm
                id='passwordConfirm'
                type='password'
                placeholder={intl.formatMessage({ id: 'main.Confirme sua nova senha' })}
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText={intl.formatMessage({ id: 'main.As senhas devem ser iguais' })}
                onInput={inputHandler}
                initialValid
              />
              <p>{intl.formatMessage({ id: 'main.Sua nova senha deve conter no mínimo 6 caracteres' })}</p>
            </Styled.DivText>
          </>
        )}
      </Modal>
    </>
  );

  const InformPassword = (
    <>
      <Modal
        title={intl.formatMessage({ id: 'main.INFORME SUA SENHA' })}
        onCancel={props.onCancel}
        onSubmit={submitHandler}
        footer={footerInformPassword}
        width='380px'
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Styled.Warning>{intl.formatMessage({ id: 'main.Informe sua senha para realizar a alteração de e-mail da plataforma:' })}</Styled.Warning>
            <Styled.DivText>
              <Styled.Title>{intl.formatMessage({ id: 'main.Senha atual' })}</Styled.Title>
              <InputForm
                id='passwordCurrent'
                type='password'
                placeholder={intl.formatMessage({ id: 'main.Informe sua senha atual' })}
                validators={[VALIDATOR_MINLENGTH(6)]}
                errorText={intl.formatMessage({ id: 'main.O campo senha deve conter no mínimo 6 caracteres' })}
                onInput={inputHandler}
              />
            </Styled.DivText>
          </>
        )}
      </Modal>
    </>
  );

  const DeveloperToken = (
    <>
      <Modal
        title={intl.formatMessage({ id: 'main.Token Desenvolvedor' }).toUpperCase()}
        onCancel={props.onCancel}
        onSubmit={submitHandler}
        footer={(tokenAccess.isActive && innerWidth > 770) ? undefined : (tokenAccess.isActive && innerWidth < 770) ? footerTokenActive : footerDeveloperToken}
        width='650px'
        showButtonClose={tokenAccess.isActive && true}
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Styled.Warning id='developerToken'>
              {intl.formatMessage({ id: 'main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações' })}
              <br />
              {intl.formatMessage({ id: 'main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia' })}
            </Styled.Warning>
            <Styled.DivToken>
              {!tokenAccess.isActive || (tokenAccess.token as string).length < 20 ? (
                <Styled.DivText>
                  <Styled.Title>{intl.formatMessage({ id: 'main.Para acessar o token, informe sua senha' })}</Styled.Title>
                  <InputForm
                    id='passwordCurrent'
                    type='password'
                    placeholder={intl.formatMessage({ id: 'main.Informe sua senha atual' })}
                    validators={[VALIDATOR_MINLENGTH(6)]}
                    errorText={intl.formatMessage({ id: 'main.O campo senha deve conter no mínimo 6 caracteres' })}
                    onInput={inputHandler}
                  />
                </Styled.DivText>
              ) : (
                <>
                  <Styled.DivText>
                    <Styled.Title>{intl.formatMessage({ id: 'main.Seu Token' })}</Styled.Title>
                    <Styled.DivInput className='token' >
                      <Styled.Input
                        id='token'
                        type='input'
                        placeholder={tokenAccess.token}
                        readOnly
                        onClick={handleCopyClick}
                      />
                      <FiCopy onClick={handleCopyClick} />
                    </Styled.DivInput>
                  </Styled.DivText>
                  <Styled.Warning>{intl.formatMessage({ id: 'main.Copie e cole o token na autenticação de todas as requisições.' })}</Styled.Warning>
                </>
              )}
            </Styled.DivToken>
          </>
        )}
      </Modal>
    </>
  );


  const EditAccount = (
    <>
      <Modal
        title={intl.formatMessage({ id: 'main.EDITAR CONTA' })}
        onCancel={props.onCancel}
        onSubmit={submitHandler}
        footer={footer}
        width='650px'
      >
        {isLoading && <Loading />}
        {!isLoading && (
          <>
            <Styled.Div>
              <Styled.Name>
                <Styled.Title>{intl.formatMessage({ id: 'main.Nome' })}</Styled.Title>
                <InputForm
                  id='userName'
                  type='text'
                  initialValid
                  initialValue={props.user.userName}
                  validators={[VALIDATOR_REQUIRE()]}
                  errorText={intl.formatMessage({ id: 'main.Preencha com o seu nome' })}
                  onInput={inputHandler}
                />
              </Styled.Name>

              <Styled.Phone>
                <Styled.Title>{intl.formatMessage({ id: 'main.Telefone' })}</Styled.Title>
                <PhoneInput
                  id='phone'
                  placeholder='+55 (    ) __________ ________'
                  international
                  value={phone}
                  onChange={setPhone}
                  limitMaxLength={true}
                />
              </Styled.Phone>
            </Styled.Div>

            <Styled.Div2>
              <Styled.Email>
                <Styled.Title>{intl.formatMessage({ id: 'main.E-mail' })}</Styled.Title>
                <Styled.Icon>
                  <InputForm
                    id='email'
                    type='email'
                    initialValid
                    initialValue={props.user.email}
                    validators={[VALIDATOR_EMAIL()]}
                    errorText={intl.formatMessage({ id: 'main.Preencha com o seu e-mail' })}
                    onInput={inputHandler}
                    disabled={!emailFieldEnabled}
                  />
                  {!emailFieldEnabled && <IoMdCreate onClick={() => setPageModal("InformPassword")} />}
                </Styled.Icon>
              </Styled.Email>
            </Styled.Div2>

            <Styled.Div className='options' >
              <Styled.DivOption>
                <IoIosLock />
                <p onClick={() => { setPageModal("ChangePassword") }}>{intl.formatMessage({ id: 'main.Alterar Senha' })}</p>
              </Styled.DivOption>
              {userDb && userDb[0].userTypeId == '1' &&
                <Styled.DivOption>
                  <BsFileEarmarkCode />
                  <p onClick={() => { setPageModal("DeveloperToken") }}>{intl.formatMessage({ id: 'main.Token Desenvolvedor' })}</p>
                </Styled.DivOption>}
            </Styled.Div>
          </>
        )}

      </Modal>
    </>
  );

  return (
    <>
      {pageModal === "EditAccount" && EditAccount}
      {pageModal === "ChangePassword" && ChangePassword}
      {pageModal === "InformPassword" && InformPassword}
      {pageModal === "DeveloperToken" && DeveloperToken}
    </>
  )
}

export default ModalEditAccount;
