import { LOCALES } from '../constants';
const pl = {
  [LOCALES.PL]: {
    //LOGIN
    "login.Login": "Zaloguj się",
    "login.Informe seu e-mail": "Podaj swój adres e-mail",
    "login.E-mail inválido. Por favor, verifique": "Nieprawidłowy adres e-mail. Proszę sprawdź",
    "login.Senha": "Hasło",
    "login.Sua senha deve possuir no mínimo 6 caracteres": "Twoje hasło musi mieć co najmniej 6 znaków",
    "login.Permanecer conectado": "Pozostań połączony",
    "login.Esqueci minha senha": "Zapomniałem hasła",
    "login.ENTRAR": "WPROWADŹ",
    "login.CRIAR CONTA": "UTWÓRZ KONTO",
    "login.Não foi possÍvel fazer o login. Verifique seus dados e tente novamente": "Nie udało się zalogować. Sprawdź swoje dane i spróbuj ponownie",
    "login.Solicitar Acesso": "Żądanie dostępu",
    "login.Utilize esta opção caso possua apenas fornos da versão antiga, que não geram PIN.": "Skorzystaj z tej opcji, jeśli masz tylko stare wersje piekarników, które nie generują PIN-u.",
    "login.A PLATAFORMA IOK MUDOU!": "PLATFORMA IOK ZMIENIŁA SIĘ!",
    "login.Não exibir este aviso novamente": "Nie pokazuj tego komunikatu ponownie",
    "login.Se você já possui um cadastro na antiga plataforma,": "Jeśli masz już rejestrację na poprzedniej platformie,",
    "login.clique aqui": "kliknij tutaj",
    "login. para migrar seus dados e aproveitar toda a experiência IOK.": ", aby przenieść swoje dane i cieszyć się pełnym doświadczeniem IOK.",
    "login.Sua conta não foi ativada. Por gentileza, verifique seu e-mail para realizar a ativação": "Twoje konto nie zostało aktywowane. Proszę zweryfikuj swój e-mail, aby je aktywować",
    "login.Para solicitar acesso a uma organização, o nome informado por você deve ser exatamente o mesmo cadastrado pelo administrador da conta. Em caso de dúvida, procure o responsável pela rede.": "Aby poprosić o dostęp do organizacji, podane przez Ciebie imię musi być dokładnie takie samo, jak zarejestrowane przez administratora konta. W razie wątpliwości, wyszukaj odpowiednią instytucję.",
    "login.Se você já possui um cadastro na antiga plataforma, ": "Jeżeli posiadasz już rejestrację na starej platformie, ",
    "login. para": " do ",
    "login.migrar": "migrować ",
    "login.seus dados e aproveitar toda a experiência IOK.": "swoje dane i ciesz się pełnym doświadczeniem IOK.",
    "login.PLATAFORMA EM MANUTENÇÃO": "PLATFORMA W KONSERWACJI",
    "login.ESTAMOS PREPARANDO A NOVA PLATAFORMA IOK E EM BREVE ELA ESTARÁ PRONTA!": "PRZYGOTUJEMY NOWĄ PLATFORMĘ IOK I JUŻ WKRÓTCE BĘDZIE GOTOWA!",
    "login.Migrar Conta": "Konto migracyjne",
    "login.Se você já possui um cadastro na antiga plataforma, clique aqui em Migrar Conta para migrar seus dados e aproveitar toda a experiência IOK": "Jeśli masz już rejestrację na starej platformie, kliknij tutaj Migracja konta, aby przeprowadzić migrację danych i cieszyć się całym doświadczeniem IOK",
    "login.Política de privacidade" : "Polityka prywatności",
    "login.ENTENDI" : "OK",
    "login.Para melhorar a sua experiência na plataforma e oferecer serviços personalizados, utilizamos cookies." : "Używamy plików cookie, aby poprawić Twoje doświadczenia na platformie i oferować spersonalizowane usługi.",
    "login.Ao utilizar a Plataforma IOK, você automaticamente concorda com seu uso." : "Korzystając z Platformy IOK automatycznie wyrażasz zgodę na jej używanie.",

    //REGISTER
    "DISTRIBUIDOR": "DYSTRYBUTOR: Jestem dystrybutorem sprzętu Prática.",
    "REDE": "SIEĆ: Mam sieć.",
    "DONO": "FIRMA: Mam własną firmę/Jestem częścią sieci.",
    "register.Informe seu e-mail cadastrado na plataforma IOK anterior": "Podaj swój e-mail zarejestrowany na poprzedniej platformie IOK",
    "register.E-mail inválido. Por favor, verifique": "Nieprawidłowy adres e-mail. Proszę sprawdź",
    "register.BUSCAR DADOS": "SZUKAJ DANYCH",
    "register.Nome da organização": "Nazwa organizacji",
    "register.Informe o nome de sua organização": "Podaj nazwę swojej organizacji",
    "register.O campo nome da organização é obrigatório": "Pole nazwy organizacji jest wymagane",
    "register.Este nome já existe. Por favor, escolha outro": "Ta nazwa już istnieje. Proszę wybrać inną",
    "register.RECUPERAR CONTA": "ODZYSKAJ KONTO",
    "register.E-mail não encontrado": "E-mail nie znaleziony",
    "register.Digite seu e-mail da plataforma IOK antiga": "Wprowadź swój e-mail z poprzedniej platformy IOK",
    "register.Não foi possível recuperar sua conta. Verifique seus dados e tente novamente": "Nie udało się odzyskać twojego konta. Sprawdź swoje dane i spróbuj ponownie",
    "register.Não foi possível recuperar seus dados. Verifique e tente novamente": "Nie udało się odzyskać twoich danych. Sprawdź i spróbuj ponownie",
    "register.PROSSEGUIR": "KONTYNUUJ",
    "register.VOCÊ ESTÁ SOLICITANDO ACESSO PARA A ORGANIZAÇÃO": "PROSZISZ O DOSTĘP DO ORGANIZACJI:",
    "register.COM O ADMINISTRADOR": "Z ADMINISTRATOREM:",
    "register.E-mail já cadastrado na plataforma. Informe um novo e-mail ou acesse a plataforma com o e-mail já cadastrado": "E-mail już zarejestrowany na platformie. Podaj nowy adres e-mail lub zaloguj się na platformie za pomocą już zarejestrowanego",
    "register.Empresa já cadastrada na plataforma. Por favor, informe o nome de uma nova empresa": "Firma już zarejestrowana na platformie. Proszę podać nową nazwę firmy",
    "register.VOCÊ NÃO RECONHECEU O USUÁRIO {userName} COMO PARTE DA SUA ORGANIZAÇÃO E POR ISSO A CONTA DELE FOI DELETADA.": "NIE ROZPOZNAŁEŚ UŻYTKOWNIKA {userName} JAKO CZĘŚCI TWOJEJ ORGANIZACJI, W ZWIĄZKU Z CZYM KONTO ZOSTAŁO USUNIĘTE.",
    "register.NÂO FOI POSSÍVEL DELETAR O USÁRIO {userName}. POR GENTILEZA, TENTE NOVAMENTE MAIS TARDE.": "NIE MOŻNA USUNĄĆ UŻYTKOWNIKA {userName}. PROSZĘ, SPRÓBUJ PONOWNIE PÓŹNIEJ.",
    "register.CONFIRMAR": "POTWIERDŹ",
    "register.Informe o ID da organização": "Podaj identyfikator organizacji",
    "register.O campo com ID é obrigatório": "Pole ID jest wymagane",
    "register.Ainda não tenho uma organização. Criar": "Nie mam jeszcze organizacji. Utwórz",
    "register.ID informado não corresponde a nenhuma companhia. Verifique e tente novamente": "Podany identyfikator nie pasuje do żadnej firmy. Sprawdź i spróbuj ponownie",
    "register.O ADMINISTRADOR DA ORGANIZAÇÃO RECEBERÁ UM E-MAIL PARA ATIVAR SUA CONTA. POR GENTILIZA, AGUARDE": "ADMINISTRATOR ORGANIZACJI OTRZYMA E-MAIL DO AKTYWACJI TWOJEGO KONTA UŻYTKOWNIKA. PROSZĘ CZEKAJ",
    "register.Este nome de franquia já possui uma conta ativa na plataforma": "Ta nazwa franczyzy ma już aktywne konto na platformie",
    "register.Não foi possível fazer o cadastro. Verifique seus dados e tente novamente": "Nie udało się zarejestrować. Sprawdź swoje dane i spróbuj ponownie",
    "register.VOCÊ RECEBERÁ UM E-MAIL PARA CONCLUIR SEU CADASTRO E ATIVAR SUA CONTA DE ADMINISTRADOR": "ODBĘDZIESZ E-MAIL DO ZAKOŃCZENIA REJESTRACJI I AKTYWACJI TWOJEGO KONTA ADMINISTRATORA",
    "register.Nome": "Imię",
    "register.Informe seu nome": "Wprowadź swoje imię",
    "register.O campo nome é obrigatório": "Pole imienia jest wymagane",
    "register.E-mail": "E-mail",
    "register.Informe seu e-mail": "Podaj swój adres e-mail",
    "register.E-mail inválido. Por favor, verifique novamente": "Nieprawidłowy adres e-mail. Proszę sprawdź",
    "register.Telefone": "Numer telefonu",
    "register.Informe seu telefone": "Podaj swój numer telefonu",
    "register.Crie sua senha": "Utwórz hasło",
    "register.Informe sua senha": "Podaj swoje hasło",
    "register.O campo senha deve conter no mínimo 6 caracteres": "Pole hasła musi mieć co najmniej 6 znaków",
    "register.Confirme sua senha": "Potwierdź hasło",
    "register.As senhas devem ser iguais": "Hasło musi być zgodne",
    "register.CRIAR CONTA": "UTWÓRZ KONTO",
    "register.CONTA ATIVADA COM SUCESSO": "KONTO AKTYWOWANE POMYŚLNIE",
    "register.ACESSAR": "DOSTĘP",
    "register.NÃO FOI POSSÍVEL ATIVAR SUA CONTA": "NIE UDAŁO SIĘ AKTYWOWAĆ TWOJEGO KONTA",
    "register.Digite seu telefone": "Podaj swój numer telefonu",
    "register.AGUARDE ENQUANTO TRANSFERIMOS SEUS DADOS. ESTE PROCESSO DEVE LEVAR ALGUNS MINUTOS. CASO VOCÊ POSSUA UMA GRANDE QUANTIDADE DE DADOS, A TRANSFERÊNCIA PODE LEVAR ATÉ UMA HORA.": "PROSZĘ CZEKAĆ, aż prześlemy Twoje dane. PROCES TEN POWINNO ZAJĄĆ KILKA MINUT. W PRZYPADKU DUŻEJ ILOŚCI DANYCH TRANSFER MOŻE TRWAĆ DO GODZINY.",
    "register.DADOS TRANSFERIDOS COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "PRZENIESIONO DANE POMYŚLNIE! KLIKNIJ PONIŻEJ, ABY WEJŚĆ NA PLATFORMĘ.",
    "register.EMAIL INVÁLIDO. POR GENTILEZA, VERIFIQUE SE SUA CONTA JÁ FOI ATIVADA.": "NIEPRAWIDŁOWY ADRES E-MAIL. SPRAWDŹ, CZY TWOJE KONTO JUŻ ZOSTAŁO AKTYWOWANE.",
    "register.A nova plataforma IOK agora conta com novas formas de acesso - que são referentes ao tipo de organização em que você atua. O responsável pela sua organização deve migrar a conta principal da empresa, se tornando o administrador, enquanto o restante dos funcionários devem se vincular a ela pelo ID fornecido.": "Nowa platforma IOK ma teraz nowe formy dostępu, związane z rodzajem organizacji, w której pracujesz. Osoba odpowiedzialna za Twoją organizację musi przenieść główne konto firmy, stając się administratorem, podczas gdy reszta pracowników musi się do niego podłączyć, używając dostarczonego identyfikatora.",
    "register.O administrador da minha organização já realizou a migração da conta e temos um ID. Desejo migrar meus dados de usuário.": "Administrator naszej organizacji już przeniósł konto, i mamy identyfikator. Chcę przenieść moje dane użytkownika.",
    "register.Desejo migrar a conta da minha organização e me tornar o administrador.": "Chcę przenieść konto naszej organizacji i zostać administratorem.",
    "register.Conta da organização já migrada": "Konto organizacji już przeniesione",
    "register.Migrar conta da organização": "Przenieś konto organizacji",
    "register.VOCÊ POSSUI MUITOS MENUS, NÃO CONSEGUIMOS REALIZAR A IMPORTAÇÃO AUTOMÁTICA. SUA CONTA SERÁ ATIVADA, PORÉM OS MENUS PRECISARÃO SER MIGRADOS MANUALMENTE.": "MASZ DUŻO MENU, NIE MOŻEMY WYKONAĆ AUTOMATYCZNEGO IMPORTU. TWOJE KONTO ZOSTANIE AKTYWOWANE, ALE MENU TRZEBA PRZEMIERZAĆ RĘCZNIE.",
    "register.PARA MIGRAR SEUS MENUS, APÓS O LOGIN, CLIQUE NO SEU NOME NO CANTO SUPERIOR DIREITO, CLIQUE EM EDITAR CONTA E DEPOIS EM IMPORTAR MENUS.": "ABY PRZENIEŚĆ SWOJE MENU, PO ZALOGOWANIU KLIKNIJ NA SWOJE IMIĘ W PRAWYM GÓRNYM ROGU, KLIKNIJ EDYTUJ KONTO, A NASTĘPNIE IMPORTUJ MENU.",
    "register.Atenção:": "Uwaga:",
    "register.apenas uma pessoa da organização pode criar a conta e, depois de ativá-la, convidar os demais usuários da empresa para acessá-la.": "tylko jedna osoba z organizacji może utworzyć konto i po jego aktywacji zaprosić pozostałych użytkowników firmy do dostępu.",
    "register.TOKEN DESENVOLVEDOR CANCELADO": "TOKEN DEWELOPERA ANULOWANY",

    //HOME
    "home.Menus disponíveis na plataforma": "Menu dostępne na platformie",
    "home.Criados na plataforma": "Utworzone na platformie",
    "home.Recebidos dos fornos": "Otrzymane z pieców",
    "home.Equipamentos vinculados": "Połączone urządzenia",
    "home.Total de receitas cadastradas": "Łączna liczba zarejestrowanych przepisów",
    "home.Receitas": "Przepisy",
    "home.Receitas em menus": "Przepisy w menu",
    "home.Em menus": "W menu",
    "home.Receitas salvas no Cookbook": "Przepisy zapisane w książce kucharskiej",
    "home.No Cookbook": "W książce kucharskiej",
    "home.Atualizações": "Aktualizacje",
    "home.Copa": "Copa",
    "home.Rocket": "Rocket",
    "home.Single Mag": "Single Mag",
    "home.Versão instalada": "Zainstalowana wersja",
    "home.Versão disponível": "Dostępna wersja",
    "home.Usuários cadastrados": "Zarejestrowani użytkownicy",
    "home.Aceitação pendente": "Oczekuje na akceptację",
    "home.Atualizado": "Zaktualizowane",
    "home.OLÁ": "CZEŚĆ",
    "home.É muito bom ter você aqui!": "To wspaniale, że tu jesteś!",
    "home.Com a Plataforma IOK é possível administrar seus fornos de onde estiver, criar e gerenciar receitas e menus, fazer backups, analisar os dados de seus equipamentos e muito mais!": "Dzięki Platformie IOK możesz zarządzać swoimi piekarnikami z dowolnego miejsca, tworzyć i zarządzać przepisami i menu, tworzyć kopie zapasowe, analizować dane ze swojego sprzętu i wiele więcej!",
    "home.Conheça a plataforma": "Odkryj platformę",
    "home.Taxa de erro por modelo": "Współczynnik błędów na model",
    "home.Erro": "Błąd",
    "home.Modelos em evidência": "Modele na pierwszym planie",
    "home.Modelos": "Modele",
    "home.Total de equipamentos vinculados": "Łączna liczba powiązanych urządzeń",
    "home.Total de equipamentos online": "Łączna liczba urządzeń online",
    "home.Equipamentos online": "Urządzenia online",
    "home.Países com Prática": "Kraje z Prática",
    "home.Países": "Kraje",
    "home.Aguarde enquanto carregamos os dados": "Proszę czekać, ładowanie danych",
    "home.Quase lá! Aguarde um pouco mais": "Prawie gotowe! Proszę chwilę poczekać",
    "home.QUEREMOS SABER SUA OPINIÃO SOBRE": "CHCEMY POZNAĆ TWOJĄ OPINIĘ NA TEMAT",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a Plataforma {model} foi criado!": "Bardzo cenimy Twój punkt widzenia, dlatego powstał ten formularz ankiety satysfakcji na Platformie {model}!",
    "home.Valorizamos muito seu ponto de vista e, por isso, este formulário de pesquisa de satisfação sobre a linha {model} foi criado!": "Nous apprécions grandement votre point de vue et c'est pourquoi ce formulaire d'enquête de satisfaction concernant la ligne {model} a été créé !",
    "home.Suas respostas nos ajudarão a aprimorar nossos serviços, garantindo que continuemos a oferecer a você a melhor experiência possível.": "Twoje odpowiedzi pomogą nam ulepszyć nasze usługi, zapewniając, że w dalszym ciągu zapewniamy najlepszą możliwą jakość usług",
    "home.Reserve uns minutinhos de seu dia e participe!": "Poświęć kilka minut swojego dnia i weź udział!",
    "home.Responder a pesquisa": "Odpowiedz na ankietę",
    "home.Vendas por Equipamento": "Sprzedaż według sprzętu",
    "home.Total de equipamentos vendidos": "Łączna sprzedaż sprzętu",
    "home.Equipamentos vendidos": "Sprzedany sprzęt",

    //MAIN
    "main.HOME": "STRONA GŁÓWNA",
    "main.COOKBOOK": "KSIĄŻKA KUCHARSKA",
    "main.MEUS MENUS": "MOJE MENU",
    "main.EQUIPAMENTOS": "SPRZĘT",
    "main.USUÁRIOS": "UŻYTKOWNICY",
    "main.ATENDIMENTOS": "USŁUGI",
    "main.Idioma": "Język",
    "main.Editar conta": "Edytuj konto",
    "main.Configurações": "Ustawienia",
    "main.Sair": "Wyloguj się",
    "main.Configurações salvas com sucesso!": "Ustawienia zapisane pomyślnie!",
    "main.SALVAR": "ZAPISZ",
    "main.CONFIGURAÇÕES": "USTAWIENIA",
    "main.Tema dark": "Ciemny motyw",
    "main.Notificações": "Powiadomienia",
    "main.Informações para as receitas": "Informacje o przepisie",
    "main.Peso:": "Waga",
    "main.Gramas": "Gramy",
    "main.Oz": "Uncje",
    "main.Temperatura:": "Temperatury:",
    "main.Celsius (°C)": "Celsjusz (°C)",
    "main.Fahrenheit (°F)": "Fahrenheit (°F)",
    "main.Conta editada com sucesso!": "Konto edytowane pomyślnie!",
    "main.EDITAR CONTA": "EDYTUJ KONTO",
    "main.Nome": "Imię",
    "main.Preencha com o seu nome": "Wypełnij swoje imię",
    "main.Telefone": "Telefon",
    "main.Preencha com o seu telefone": "Wypełnij swój numer telefonu",
    "main.E-mail": "E-mail",
    "main.Preencha com o seu e-mail": "Wypełnij swój adres e-mail",
    "main.Alterar Senha": "Zmień hasło",
    "main.ALTERAR SENHA": "ZMIEŃ HASŁO",
    "main.Senha atual": "Aktualne hasło",
    "main.Nova senha": "Nowe hasło",
    "main.Confirmar nova senha": "Potwierdź nowe hasło",
    "main.Informe sua senha atual": "Wprowadź swoje aktualne hasło",
    "main.O campo senha deve conter no mínimo 6 caracteres": "Pole hasła musi mieć co najmniej 6 znaków",
    "main.Informe sua nova senha": "Wprowadź nowe hasło",
    "main.Confirme sua nova senha": "Potwierdź nowe hasło",
    "main.Sua nova senha deve conter no mínimo 6 caracteres": "Twoje nowe hasło musi mieć co najmniej 6 znaków",
    "main.INFORME SUA SENHA": "WPISZ SWOJE HASŁO",
    "main.Informe sua senha para realizar a alteração de e-mail da plataforma:": "Wprowadź swoje hasło, aby zmienić swój adres e-mail na platformie:",
    "main.CONFIRMAR": "POTWIERDŹ",
    "main.Existem campos que não foram preenchidos corretamente": "Są pola, które nie zostały poprawnie wypełnione",
    "main.Informe sua senha para prosseguir com a alteração de e-mail": "Wprowadź swoje hasło, aby kontynuować zmianę swojego adresu e-mail",
    "main.Senha alterada com sucesso": "Hasło zostało pomyślnie zmienione",
    "main.Informe sua senha atual corretamente": "Wprowadź poprawnie swoje aktualne hasło",
    "main.As senhas devem ser iguais": "Hasła muszą być zgodne",
    "main.Nome do novo tipo de usuário": "Nowa nazwa rodzaju użytkownika",
    "main.Dê um nome para o novo tipo de usuário": "Nadaj nazwę nowemu rodzajowi użytkownika",
    "main.Token Desenvolvedor": "Token Dewelopera",
    "main.O Token Desenvolvedor é um código único e intransferível que permite a realização de alterações": "Token dewelopera to unikalny i nieprzekazywalny kod, który umożliwia wprowadzanie zmian na poziomie deweloperskim bezpośrednio w kodzie platformy za pomocą zewnętrznych interfejsów API. Pozwalają one na dostosowanie platformy, aby lepiej zaspokoić potrzeby Twojej firmy.",
    "main.É recomendado que estas alterações sejam realizadas por um profissional da área de tecnologia": "Zaleca się, aby te zmiany zostały dokonane przez specjalistę z dziedziny technologii.",
    "main.Para acessar o token, informe sua senha": "Aby uzyskać dostęp do tokena, podaj swoje hasło.",
    "main.Copie e cole o token na autenticação de todas as requisições.": "Skopiuj i wklej token do uwierzytelniania wszystkich żądań.",
    "main.Seu Token": "Twój token",
    "main.Token copiado com sucesso!": "Token skopiowany pomyślnie!",
    "main.Você deseja cancelar o preenchimento das informações?": "Czy chcesz anulować wypełnianie informacji?",
    "main.Rever tutoriais": "Przegląd samouczków",
    "main.Tutoriais resetados com sucesso": "Samouczki zostały pomyślnie zresetowane",
    "main.review.tutorials": "Czy chcesz ponownie przejrzeć samouczki na platformie IOK?",

    //MENUS
    "menus.Procurar menu": "Menu wyszukiwania lub użytkownik",
    "menus.Procurar menu excluído": "Przeszukaj usunięte menu",
    "menus.Menus": "Menu",
    "menus.Lixeira": "Kosz",
    "menus.CRIAR MENU": "UTWÓRZ MENU",
    "menus.IMPORTAR MENU": "IMPORTUJ MENU",
    "menus.Os arquivos serão mantidos na lixeira por 30 dias a contar de sua data de exclusão. Após este período, eles serão excluídos definitivamente.": "Pliki będą przechowywane w koszu przez 30 dni od daty usunięcia. Po tym okresie zostaną trwale usunięte.",
    "menus.VOLTAR": "POWRÓT",
    "menus.Compatível com: ": "Zgodność z: ",
    "menus.Última atualização: ": "Ostatnia aktualizacja: ",
    "menus.Última atualização": "Ostatnia aktualizacja",
    "menus.EXPORTAR MENU": "EKSPORTUJ MENU",
    "menus.EDITAR MENU": "EDYTUJ MENU",
    "menus.Equipamentos associados": "Powiązane wyposażenie",
    "menus.Grupos": "Grupy",
    "menus.Procurar grupo": "Przeszukaj grupę",
    "menus.CRIAR GRUPO": "UTWÓRZ GRUPĘ",
    "menus.receitas": "Przepisy",
    "menus.Nenhum grupo cadastrado": "Brak zarejestrowanych grup",
    "menus.Nenhum menu cadastrado": "Brak zarejestrowanych menu",
    "menus.Nome": "Nazwa",
    "menus.Modelo": "Model",
    "menus.Atividade": "Aktywność",
    "menus.Associação": "Powiązanie",
    "menus.Exportar": "Eksport",
    "menus.Editar": "Edytuj",
    "menus.Excluir": "Usuń",
    "menus.Equips": "Wyposaż",
    "menus.Receitas": "Przepisy",
    "menus.Compatibilidade": "Zgodność",
    "menus.Versão": "Wersja",
    "menus.Origem": "Pochodzenie",
    "menus.Excluído por: ": "Usunięte przez: ",
    "menus.Restaurar": "Przywróć",
    "menus.Existem campos que não foram preenchidos": "Istnieją pola, które nie zostały wypełnione",
    "menus.Menu criado com sucesso!": "Menu zostało utworzone pomyślnie!",
    "menus.Existem campos com informações inválidas": "Istnieją pola z nieprawidłowymi informacjami",
    "menus.SALVAR": "ZAPISZ",
    "menus.CANCELAR": "ANULUJ",
    "menus.Nome do menu:": "Nazwa menu: ",
    "menus.Informe o nome do menu": "Podaj nazwę menu",
    "menus.Preencha o nome do menu sem caracteres especiais": "Wypełnij nazwę menu bez znaków specjalnych",
    "menus.Selecionar": "Wybierz",
    "menus.Idioma": "Język",
    "menus.Temperatura de pré-aquec 1:": "Temperatura rozgrzewania 1:",
    "menus.Informe a temperatura de pré-aquec 1": "Podaj temperaturę rozgrzewania 1",
    "menus.Temperatura de pré-aquec 2:": "Temperatura rozgrzewania 2:",
    "menus.Informe a temperatura de pré-aquec 2": "Podaj temperaturę rozgrzewania 2",
    "menus.Tempo para estabilização:": "Czas stabilizacji:",
    "menus.Informe o tempo para estabilização": "Podaj czas stabilizacji",
    "menus.Desativar pré-aquec 2": "Dezaktywuj rozgrzewanie 2",
    "menus.Configurações de data e hora": "Ustawienia daty i czasu",
    "menus.Data:": "Data:",
    "menus.DD/MM/AAAA": "DD/MM/YYYY",
    "menus.Hora:": "Godziny:",
    "menus.24h": "24h",
    "menus.AM/PM": "AM/PM",
    "menus.EUR: DD/MM/AAAA": "EUR: DD/MM/YYYY",
    "menus.USA: MM/DD/AAAA": "USA: MM/DD/YYYY",
    "menus.ISO: AAAA/MM/DD": "ISO: YYYY/MM/DD",
    "menus.Seleção de acessos para habilitar": "Wybór dostępu do włączenia",
    "menus.Modo manual": "Tryb manualny",
    "menus.Habilitado": "Możliwe",
    "menus.Desabilitado": "Wyłączone",
    "menus.Favoritos": "Ulubione",
    "menus.Aquecer/Dourar": "Nagrzewanie / Brązowanie",
    "menus.Repetir receita": "Powtórz przepis",
    "menus.Configurações gerais do menu": "Ogólne ustawienia menu",
    "menus.Temperatura": "Temperatura",
    "menus.Temperatura:": "Temperatura:",
    "menus.Celsius (°C)": "Celsjusz (°C)",
    "menus.Fahrenheit (°F)": "Fahrenheit (°F)",
    "menus.Peso": "Waga",
    "menus.Gramas": "Gramy",
    "menus.Oz": "Uncje",
    "menus.Tema:": "Motyw:",
    "menus.Dark": "Ciemny",
    "menus.Light": "Jasny",
    "menus.Introdução:": "Wprowadzenie:",
    "menus.Ativada": "Aktywowane",
    "menus.Desativada": "Dezaktywowane",
    "menus.Menu deletado com sucesso!": "Menu pomyślnie usunięte!",
    "menus.Não foi possivel deletar o menu": "Nie można usunąć menu",
    "menus.Sim": "Tak",
    "menus.Não": "Nie",
    "menus.Excluir definitivamente": "Trwale usuń",
    "menus.SALVAR EM PEN DRIVE": "ZAPISZ NA DYSK FLASH",
    "menus.EXPORTAR": "EKSPORTUJ",
    "menus.Selecione os modelos e versões dos equipamentos que receberão o menu.": "Wybierz modele i wersje sprzętu, aby otrzymać menu",
    "menus.Forno": "Piekarnik",
    "menus.Fundo verde": "Zielone tło",
    "menus.Fundo preto": "Czarne tło",
    "menus.Linux": "Linux",
    "menus.Atenção: antes de transferir o menu de seu pen drive para o equipamento, é necessário descompactar o arquivo, exceto para menus de equipamentos Linux.": "Uwaga: przed przesłaniem menu z dysku flash do sprzętu, konieczne jest rozpakowanie pliku, z wyjątkiem menu dla sprzętu z systemem Linux.",
    "menus.Selecione os equipamentos para os quais deseja exportar o menu.": "Wybierz sprzęt, dla którego chcesz wyeksportować menu.",
    "menus.Nome do equipamento": "Nazwa sprzętu",
    "menus.Selecionar todos": "Zaznacz wszystko",
    "menus.Selecionar todas": "Zaznacz wszystko",
    "menus.Categoria: ": "Kategoria: ",
    "menus.Você deseja excluir o menu?": "Czy chcesz usunąć menu?",
    "menus.O menu será deletado da plataforma, mas permanecerá no equipamento. Uma cópia de segurança será criada automaticamente e ficará disponível por 30 dias em Meus Menus - Lixeira.": "Menu zostanie usunięte z platformy, ale pozostanie na sprzęcie. Automatycznie zostanie utworzona kopia zapasowa, dostępna przez 30 dni w Moje Menu - Kosz.",
    "menus.Você deseja excluir o arquivo definitivamente?": "Czy chcesz trwale usunąć plik?",
    "menus.Esta ação não poderá ser desfeita.": "Tej operacji nie można cofnąć.",
    "menus.Menu editado com sucesso!": "Menu pomyślnie edytowane!",
    "menus.IMPORTAR": "IMPORTUJ",
    "menus.VERIFICAÇÃO DE VERSÃO": "SPRAWDZANIE WERSJI",
    "menus.Para conferir a versão da placa de seu equipamento, siga os seguintes passos:": "Aby sprawdzić wersję płyty sprzętu, postępuj zgodnie z poniższymi krokami:",
    "menus.No forno, acesse Configurações;": "W piekarniku przejdź do Ustawień;",
    "menus.Insira a senha 456789;": "Wprowadź hasło 456789;",
    "menus.Selecione a opção Sistema;": "Wybierz opcję System;",
    "menus.Selecione Informações do Fabricante;": "Wybierz Informacje o producencie;",
    "menus.O modelo de sua placa estará na linha Firmware IHM;": "Model twojej płyty będzie widoczny w linii Firmware IHM;",
    "menus.1ª geração A": "1. generacja A",
    "menus.Firmware IHM: 12.3.4": "Firmware IHM: 12.3.4",
    "menus.1ª geração B": "1. generacja B",
    "menus.2ª geração/Linux:": "2. generacja/Linux",
    "menus.Firmware IHM: L1.2.3": "Firmware IHM: L1.2.3",
    "menus.Selecione o modelo/versão do equipamento do qual deseja importar o menu. Saiba como conferir a versão de seu equipamento": "Wybierz model/wersję sprzętu, z którego chcesz zaimportować menu. Dowiedz się, jak sprawdzić wersję swojego sprzętu ",
    "menus.Selecione o modelo/versão do equipamento": "Wybierz model/wersję urządzenia",
    "menus.Como verificar a versão da placa": "Jak sprawdzić wersję płyty",
    "menus.1° Geração": "1. generacja",
    "menus.Menu restaurado com sucesso!": "Menu przywrócone pomyślnie!",
    "menus.Não foi possivel restaurar o menu": "Nie można przywrócić menu",
    "menus.RESTAURAR ARQUIVO": "PRZYWRÓĆ PLIK",
    "menus.Você deseja restaurar o arquivo?": "Czy chcesz przywrócić plik?",
    "menus.O arquivo será restaurado como uma nova versão na plataforma, na guia Todos. Para salvá-lo no equipamento, exporte-o.": "Plik zostanie przywrócony jako nowa wersja na platformie w zakładce Wszystkie. Aby zapisać go na swoim urządzeniu, wyeksportuj go.",
    "menus.EXCLUIR MENU": "USUŃ MENU",
    "menus.Nenhum equipamento associado": "Brak powiązanego sprzętu",
    "menus.Nomeie o novo menu": "Nadaj nazwę nowemu menu",
    "menus.Nomeie o novo grupo": "Nadaj nazwę nowej grupie",
    "menus.Nome do grupo:": "Nazwa grupy: ",
    "menus.Preencha o nome do grupo sem caracteres especiais": "Wypełnij nazwę grupy bez znaków specjalnych",
    "menus.Pré-aquecimento": "Rozgrzewanie",
    "menus.Pré-aquecimento:": "Rozgrzewanie:",
    "menus.Grupo criado com sucesso!": "Grupa utworzona pomyślnie!",
    "menus.SELECIONE UMA IMAGEM": "WYBIERZ OBRAZ",
    "menus.SELECIONAR": "WYBIERZ",
    "menus.Selecionar do computador": "Wybierz z komputera",
    "menus.Selecionar do banco de imagens": "Wybierz z banku obrazów",
    "menus.Formato .JPG ou .PNG": "Format .JPG lub .PNG",
    "menus.Procurar imagem": "Przeszukaj obraz",
    "menus.Pertence ao menu": "Należy do menu",
    "menus.Alterar imagem": "Zmień obraz",
    "menus.Remover imagem": "Usuń obraz",
    "menus.EDITAR GRUPO": "EDYTUJ GRUPĘ",
    "menus.CRIAR RECEITA": "UTWÓRZ PRZEPIS",
    "menus.SALVAR DO COOKBOOK": "ZAPISZ Z KSIĄŻKI KUCHARSKIEJ",
    "menus.Procurar receita do grupo": "Przeszukaj przepisy grupy",
    "menus.Ingrediente principal": "Główny składnik",
    "menus.Mover receitas": "Przenieś przepisy",
    "menus.Excluir receitas": "Usuń przepisy",
    "menus.Editar grupo": "Edytuj grupę",
    "menus.Excluir grupo": "Usuń grupę",
    "menus.aqui": "tutaj",
    "menus.Se o código possuir apenas números e o fundo da tela for verde, pertence à 1ª A, se possuir apenas números e o fundo da tela for preta, pertence à 1ª B e, se começar com as letras L, F ou S, sua placa pertence à 2ª geração/Linux.": "Jeśli kod zawiera tylko liczby, a tło jest zielone, należy do 1. generacji A; jeśli zawiera tylko liczby, a tło jest czarne, należy do 1. generacji B; jeśli zaczyna się od liter L, F lub S, płyta należy do 2. generacji/Linux.",
    "menus.1ª A - Fundo verde": "1. generacja A - Zielone tło",
    "menus.1ª B - Fundo preto": "1. generacja B - Czarne tło",
    "menus.2ª - Linux": "2. generacja - Linux",
    "menus.Tivemos um problema. Tente novamente mais tarde.": "Wystąpił problem. Spróbuj ponownie później.",
    "menus.Grupo editado com sucesso!": "Grupa edytowana pomyślnie!",
    "menus.AINDA NÃO HÁ GRUPOS AQUI :(": "TUTAJ JESZCZE NIE MA GRUP :(",
    "menus.NENHUM MENU NA LIXEIRA": "BRAK MENU W KOSZU",
    "menus.AINDA NÃO HÁ MENUS AQUI :(": "TUTAJ JESZCZE NIE MA MENU :(",
    "menus.Adicionar imagem": "Dodaj obraz",
    "menus.Nome da receita": "Nazwa przepisu",
    "menus.Origem da receita": "Pochodzenie przepisu",
    "menus.Detalhes da receita": "Szczegóły przepisu",
    "menus.Programação da receita": "Programowanie przepisu",
    "menus.Nomeie sua receita": "Nadaj nazwę swojemu przepisowi",
    "menus.Preencha o nome da receita sem caracteres especiais": "Wypełnij nazwę przepisu bez znaków specjalnych",
    "menus.Tipo de prato": "Typ posiłku",
    "menus.SALVAR RECEITAS DO COOKBOOK": "ZAPISZ PRZEPISY Z KSIĄŻKI KUCHARSKIEJ",
    "menus.Selecione as receitas que deseja salvar no grupo de seu menu:": "Wybierz przepisy, które chcesz zapisać w grupie swojego menu:",
    "menus.Procurar receita": "Przeszukaj przepis",
    "menus.Grupo deletado com sucesso!": "Grupa usunięta pomyślnie!",
    "menus.Não foi possivel deletar o grupo": "Nie można usunąć grupy",
    "menus.EXCLUIR GRUPO": "USUŃ GRUPĘ",
    "menus.Você deseja excluir o grupo definitivamente?": "Czy chcesz trwale usunąć grupę?",
    "menus.AINDA NÃO HÁ RECEITAS AQUI :(": "TUTAJ JESZCZE NIE MA PRZEPISÓW :(",
    "menus.Temp. de entrada": "Temperatura początkowa",
    "menus.Temp. de pré-aquec": "Temperatura rozgrzewania",
    "menus.Ingredientes para montagem": "Składniki do złożenia",
    "menus.Instruções operacionais": "Instrukcje obsługi",
    "menus.Passo": "Krok",
    "menus.Temp": "Temp ",
    "menus.Temp.": "Temp. ",
    "menus.Tempo": "Czas",
    "menus.Velocidade do ar": "Prędkość powietrza",
    "menus.Micro-ondas": "Mikrofalówka",
    "menus.Resistência inferior": "Odporność dolna",
    "menus.Informações": "Informacje",
    "menus.Digite as informações do passo": "Wprowadź informacje o kroku",
    "menus.desativar": "dezaktywuj",
    "menus.Ingredientes": "Składniki",
    "menus.Temperatura de entrada": "Temperatura początkowa",
    "menus.Equipamentos compatíveis": "Zgodny sprzęt",
    "menus.SALVAR NO COOKBOOK": "ZAPISZ W KSIĄŻCE KUCHENNEJ",
    "menus.ver localização": "zobacz lokalizację",
    "menus.SELECIONE RECEITAS PARA EXCLUIR": "WYBIERZ PRZEPISY DO USUNIĘCIA",
    "menus.Você deseja excluir as receitas selecionadas definitivamente": "Czy chcesz definitywnie usunąć wybrane przepisy?",
    "menus.Receitas deletadas com sucesso!": "Przepisy zostały pomyślnie usunięte!",
    "menus.Informe aqui os ingredientes para montagem": "Podaj tutaj składniki do montażu",
    "menus.Informe aqui as instruções operacionais": "Podaj tutaj instrukcję obsługi",
    "menus.Adicionar passo": "Dodaj krok",
    "menus.Receita criada com sucesso!": "Przepis został utworzony pomyślnie!",
    "menus.Ideal para:": "Idealne dla: ",
    "menus.editar detalhes": "edytować szczegóły",
    "menus.excluir": "usuwać",
    "menus.editar": "edytować",
    "menus.Receita editada com sucesso!": "Przepis został pomyślnie edytowany!",
    "menus.Passo deletado com sucesso!": "Krok został pomyślnie usunięty!",
    "menus.Não foi possivel deletar o passo": "Nie można usunąć kroku",
    "menus.EXCLUIR PASSO": "USUŃ KROK",
    "menus.Você deseja excluir este passo": "Czy chcesz usunąć ten krok?",
    "menus.Esta ação não poderá ser desfeita. Caso prefira, é possível apenas desativá-lo, mantendo assim sua configuração.": "Tej akcji nie można cofnąć. Jeśli wolisz, możesz ją jedynie dezaktywować, zachowując ustawienia. ",
    "menus.Plataforma": "Platforma",
    "menus.Receita salva com sucesso!": "Przepis został pomyślnie zapisany!",
    "menus.Exportar receitas": "Eksportuj przepisy",
    "menus.expandir": "zwiększać",
    "menus.Vapor combinado": "Połączona para",
    "menus.Ar quente": "Gorące powietrze",
    "menus.Sonda": "Sonda",
    "menus.Tempo (min)": "Czas (min)",
    "menus.Umidade (%)": "Wilgotność (%)",
    "menus.Injeção de vapor": "Wtrysk pary",
    "menus.Dumper aberto": "Otwórz wywrotkę",
    "menus.Informe o peso": "Poinformuj o wadze",
    "menus.Informe a temperatura de entrada": "Podaj temperaturę początkową",
    "menus.Informe os ingredientes para montagem": "Wprowadź składniki do montażu",
    "menus.Informe as instruções operacionais": "Wprowadź instrukcje obsługi",
    "menus.Injeção de vapor (seg)": "Wstrzymanie pary (s)",
    "menus.Aquecer mais": "Rozgrzewanie więcej",
    "menus.Dourar mais": "Brązowienie więcej",
    "menus.Aquecer e dourar mais": "Rozgrzewanie i brązowienie więcej",
    "menus.Favoritar receita": "Ulubiony przepis",
    "menus.Informe o tempo para aquecer mais de 0 a 59 segundos": "Podaj czas rozgrzewania więcej od 0 do 59 sekund",
    "menus.Informe o tempo para dourar mais de 0 a 59 segundos": "Podaj czas brązowienia więcej od 0 do 59 sekund",
    "menus.Informe o tempo para aquecer e dourar mais de 0 a 59 segundos": "Podaj czas rozgrzewania i brązowienia więcej od 0 do 59 sekund",
    "menus.Apenas favoritos": "Tylko ulubione",
    "menus.Forno combinado": "Kombinowana kuchenka",
    "menus.Cocção múltipla": "Wielokrotne gotowanie",
    "menus.Receitas Technicook": "Przepisy Technicook",
    "menus.Editar grupos": "Edytuj grupy",
    "menus.Informe a data": "Podaj datę",
    "menus.Informe a hora": "Podaj godzinę",
    "menus.até 300": "do 300",
    "menus.Vapor": "Para",
    "menus.Turbina": "Turbina",
    "menus.Criar grupo": "Utwórz grupę",
    "menus.MOVER": "PRZENIEŚ",
    "menus.Configurações de acessos": "Ustawienia dostępu:",
    "menus.SELECIONE RECEITAS PARA MOVER": "WYBIERZ PRZEPISY DO PRZENIESIENIA",
    "menus.AVANÇAR": "DALEJ",
    "menus.Selecione ou crie um grupo no mesmo menu para mover as receitas selecionadas ou as mova para outro menu. Note que apenas os menus/grupos compatíveis com as predefinições das receitas serão exibidos.": "Wybierz lub utwórz grupę w tym samym menu, aby przenieść wybrane przepisy lub przenieś je do innego menu. Zauważ, że będą wyświetlane tylko te menu/grupy, które są kompatybilne z ustawieniami przepisu.",
    "menus.Mover receitas para o mesmo menu": "Przenieś przepisy do tego samego menu",
    "menus.Mover receitas para outro menu": "Przenieś przepisy do innego menu",
    "menus.Selecionar grupo": "Wybierz grupę",
    "menus.Nenhuma receita foi selecionada": "Nie wybrano żadnego przepisu",
    "menus.Passo editado com sucesso!": "Krok pomyślnie edytowany!",
    "menus.Menu atualizado": "Menu zaktualizowane",
    "menus.até 260": "do 260",
    "menus.Umidade na câmara": "Wilgotność komory",
    "menus.Passos": "Kroki",
    "menus.ativar": "aktywuj",
    "menus.Informe a temperatura de pré-aquec": "Podaj temperaturę rozgrzewania",
    "menus.Movas as receitas selecionadas para outro menu. Note que apenas os menus compatíveis com as predefinições das receitas serão exibidos.": "Przenieś wybrane przepisy do innego menu. Zauważ, że będą wyświetlane tylko te menu, które są kompatybilne z ustawieniami przepisu.",
    "menus.Sonda (°C)": "Sonda (ºC)",
    "menus.Turbo Grill": "Turbo Grill",
    "menus.Pré-aquecimento padrão, conforme passo 1": "Standardowe rozgrzewanie, zgodnie z krokiem 1",
    "menus.salvar": "zapisz",
    "menus.até": "do",
    "menus.Verificação de receita": "Sprawdzenie przepisu",
    "menus.Auto import": "Automatyczny import",
    "menus.Atualização forçada": "Wymuszone aktualizacje",
    "menus.Lista de receitas exportada com sucesso!": "Lista przepisów pomyślnie wyeksportowana!",
    "menus.Receita movida com sucesso!": "Przepis przeniesiony pomyślnie!",
    "menus.Receitas movidas com sucesso!": "Przepisy przeniesione pomyślnie!",
    "menus.+ Receita": "Przepis",
    "menus.Escolha um idioma compatível com o equipamento": "Wybierz język kompatybilny z wyposażeniem",
    "menus.MENU IMPORTADO COM SUCESSO!": "Menu zostało pomyślnie zaimportowane!",
    "menus.Dourar": "Brązowy",
    "menus.Não atribuir a menu": "Nie przypisuj do menu",
    "menus.Todos": "Wszystkie",
    "menus.Meus menus": "Moje Menu",
    "menus.Para salvar uma receita, preencha todos os campos": "Aby zapisać przepis, wypełnij wszystkie pola",
    "menus.Selecione os grupos de equipamentos para os quais deseja exportar o menu.": "Wybierz grupy urządzeń, dla których chcesz wyeksportować menu.",
    "menus.Você deseja excluir este passo?": "Czy chcesz usunąć ten krok?",
    "menus.Formato inválido. São permitidos apenas os formatos .jpeg e .png": "Nieprawidłowy format. Dozwolone są tylko formaty .jpeg i .png.",
    "menus.Reordenar grupos": "Sortowanie grup",
    "menus.Reordenar receitas": "Sortowanie przepisów",
    "menus.ITENS REORDENADOS COM SUCESSO! PARA APLICAR AS ALTERAÇÕES NO FORNO, EXPORTE O MENU.": "ELEMENTY ZMODYFIKOWANE POMYŚLNIE! ABY ZASTOSOWAĆ ZMIANY W PIEKARNIKU, WYEXPORTUJ MENU.",
    "menus.ARRASTE OS ITENS PARA O LOCAL DESEJADO": "Przeciągnij elementy w żądane miejsce.",
    "menus.Dumper": "Wywrotka",
    "menus.Aberto": "otwarty",
    "menus.Fechado": "Zamknięte",
    "menus.Intermitente": "Przerywany",
    "menus.Se o seu menu incluir itens sensíveis à uniformidade, recomendamos definir o Tempo de Estabilização entre 3:00 e 5:00 minutos. Caso contrário, você pode deixá-lo em 0:00 minutos.": "Jeżeli w Twoim menu znajdują się pozycje wrażliwe na jednolitość, zalecamy ustawienie czasu ustalania w przedziale od 3:00 do 5:00 minut. W przeciwnym razie możesz pozostawić go o godzinie 0:00.",
    "menus.Selecione o menu que deseja exportar:": "Wybierz menu, które chcesz wyeksportować:",
    "menus.Temperatura de pré-aquecimento:": "Temperatura rozgrzewania:",
    "menus.Temperatura de lastro": "Temperatura balastu",
    "menus.Temperatura de teto": "Temperatura sufitu",
    "menus.Volume de injeção de vapor (ml)": "Objętość wtrysku pary (ml)",
    "menus.Parada de turbina": "Zatrzymanie turbiny",
    "menus.Alta": "Wysoka",
    "menus.Média": "Średnia",
    "menus.Baixa": "Niska",
    "menus.a": "the",
    "menus.Valor da injeção": "Wartość wtrysku",
    "menus.Volume: até 999ml": "Objętość: do 999ml",
    "menus.Tempo: até 15 segundos": "Czas: do 15 sekund",
    "menus.Tempo de injeção de vapor (seg)": "Czas wtrysku pary (sek)",
    "menus.Atenção!": "Uwaga!",
    "menus.Verifique as receitas do menu e ajuste os valores da Injeção de Vapor após alterar sua unidade de medida.": "Sprawdź przepisy menu i dostosuj wartości wtrysku pary po zmianie jednostki miary.",
    "menus.Certifique-se de que a unidade de temperatura (°C ou °F) definida no equipamento seja a mesma que foi selecionada na criação do menu:": "Upewnij się, że jednostka temperatury (°C lub °F) zdefiniowana na urządzeniu jest taka sama, jak wybrana podczas tworzenia menu:",
    "menus.Injeção desativada": "Wtrysk wyłączony",
    "menus.Selecione a unidade de temperatura que está definida no equipamento:": "Wybierz jednostkę temperatury zdefiniowaną na urządzeniu:",
    "menus.Clique para atualizar": "Kliknij, aby zaktualizować",
    "menus.A função Atualização Forçada envia automaticamente o menu para os equipamentos selecionados na próxima vez em que forem ligados.": "Funkcja Wymuszonej aktualizacji automatycznie wysyła menu do wybranego urządzenia przy następnym włączeniu.",
    "menus.As opções selecionadas estarão disponíveis nos equipamentos que receberem este menu, podendo ser utilizadas pelo operador. É possível alterar estas configurações posteriormente no próprio forno ou pela plataforma.": "Wybrane opcje będą dostępne na sprzęcie, który otrzyma to menu i mogą być używane przez operatora. Ustawienia te można później zmienić w samym piekarniku lub za pośrednictwem platformy.",
    "menus.+ Menu": "+ Menu",
    "menus.Não foi possivel deletar as receitas": "Nie można usunąć przepisów",
    "menus.PRÉ": "PRZED",

    //COOKBOOK
    "cookbook.RECEITAS DISPONÍVEIS": "DOSTĘPNE PRZEPISY",
    "cookbook.RECEITAS SALVAS": "ZAPISANE PRZEPISY",
    "cookbook.ENVIAR PARA MENU": "WYŚLIJ DO MENU",
    "cookbook.SALVAR": "ZAPISZ",
    "cookbook.salvar": "zapisz",
    "cookbook.Filtrar por": "Filtruj według",
    "cookbook.Procurar receita do cookbook": "Wyszukaj przepis z książki kucharskiej",
    "cookbook.Equipamento": "Sprzęt",
    "cookbook.Ingrediente principal": "Główny składnik",
    "cookbook.Tipo de prato": "Rodzaj posiłku",
    "cookbook.Região": "Region",
    "cookbook.Pré-aquecimento": "Rozgrzewanie",
    "cookbook.Procurar receita": "Wyszukaj przepis",
    "cookbook.Menu": "Menu",
    "cookbook.ENVIAR": "WYŚLIJ",
    "cookbook.Procurar nome do menu": "Wyszukaj nazwę menu",
    "cookbook.Escolha um grupo deste menu": "Wybierz grupę z tego menu",
    "cookbook.Selecionar grupo": "Wybierz grupę",
    "cookbook.Tivemos um problema. Tente novamente mais tarde": "Mamy problem. Spróbuj ponownie później",
    "cookbook.Receita adicionada com sucesso!": "Przepis dodany pomyślnie!",
    "cookbook.Não foi possível adicionar a receita. Tente novamente mais tarde": "Nie udało się dodać przepisu. Spróbuj ponownie później",
    "cookbook.Selecione um menu e um grupo para enviar esta receita": "Wybierz menu i grupę, do której chcesz wysłać ten przepis",
    "cookbook.Selecione o modelo do equipamento primeiro": "Najpierw wybierz model sprzętu",
    "cookbook.Grupo": "Grupa",
    "cookbook.Não foi possível salvar os passos da receita": "Nie udało się zapisać kroków przepisu",
    "cookbook.Bovina": "Wołowina",
    "cookbook.Frango": "Kurczak",
    "cookbook.Ovos": "Jajka",
    "cookbook.Pães": "Chleby",
    "cookbook.Peixes": "Ryba",
    "cookbook.Queijo": "Ser",
    "cookbook.Suína": "Wieprzowina",
    "cookbook.Vegetais": "Warzywa",
    "cookbook.Outros": "Inne",
    "cookbook.Entradas/aperitivos": "Przystawki",
    "cookbook.Principais": "Dania główne",
    "cookbook.Snacks": "Przekąski",
    "cookbook.Pizzas": "Pizze",
    "cookbook.Massas": "Makarony",
    "cookbook.Acompanhamentos": "Dodatek",
    "cookbook.Sobremesas": "Desery",
    "cookbook.Manhã": "Śniadania",
    "cookbook.Padaria": "Piekarnia",
    "cookbook.Todos": "Wszystko",
    "cookbook.Não atribuir origem": "Nie przypisuj pochodzenia",
    "cookbook.Brasil": "Brazylia",
    "cookbook.Alemanha": "Niemcy",
    "cookbook.França": "Francja",
    "cookbook.Rússia": "Rosja",
    "cookbook.China": "Chiny",
    "cookbook.Japão": "Japonia",
    "cookbook.Itália": "Włochy",
    "cookbook.Egito": "Egipt",
    "cookbook.Grécia": "Grecja",
    "cookbook.Argentina": "Argentyna",
    "cookbook.África": "Afryka",
    "cookbook.Estados Unidos": "Stany Zjednoczone",
    "cookbook.Coreia": "Korea",
    "cookbook.Turquia": "Turcja",
    "cookbook.Ideal para": "Idealne do",
    "cookbook.Polônia": "Polska",
    "cookbook.Sanduíches": "Kanapki",

    //EQUIPAMENTOS
    "equipment.equipamentos": "Sprzęt",
    "equipment.procurar equipamento": "Wyszukaj sprzęt lub użytkownika",
    "equipment.adicionar equipamento": "Dodaj sprzęt",
    "equipment.atualizar equipamento": "Aktualizuj sprzęt",
    "equipment.+ equipamentos": "Sprzęt",
    "equipment.buscar equipamento": "Wyszukaj sprzęt",
    "equipment.Desvincular": "Odłącz",
    "equipment.Editar": "Edytuj",
    "equipment.sem conteúdo": "jeszcze nie ma tutaj żadnego sprzętu :(",
    "equipment.editar equipamento": "edytuj sprzęt",
    "equipment.equipamento editado com sucesso!": "Sprzęt pomyślnie edytowany!",
    "equipment.não foi possível editar o equipamento. Tente novamente mais tarde": "Nie udało się edytować sprzętu. Spróbuj ponownie później",
    "equipment.Menu exportado com sucesso!": "Menu wyeksportowane pomyślnie!",
    "equipment.Não foi possível exportar o menu. Tente novamente mais tarde": "Nie udało się wyeksportować menu. Spróbuj ponownie później",
    "equipment.Selecione pelo menos um equipamento para enviar o menu": "Wybierz co najmniej jeden sprzęt do wysłania menu",
    "equipment.Informações do menu": "Informacje o menu",
    "equipment.Procurar receita ou grupo": "Wyszukaj przepis lub grupę",
    "equipment.Grupo": "Grupa",
    "equipment.Receita": "Przepis",
    "equipment.Quantidade": "Ilość",
    "equipment.Receitas mais preparadas no equipamento durante o período": "Najczęściej przygotowane przepisy na urządzeniu w okresie",
    "equipment.Informações de limpeza": "Informacje o czyszczeniu",
    "equipment.Data da limpeza": "Data czyszczenia",
    "equipment.Hora da limpeza": "Godzina czyszczenia",
    "equipment.nome": "Nazwa",
    "equipment.modelo": "Model",
    "equipment.categoria": "Kategoria",
    "equipment.loja": "Sklep",
    "equipment.desvincular equipamento": "Odłącz sprzęt",
    "equipment.unlink.paragrafo 1": "Czy chcesz odłączyć sprzęt od platformy IOK?",
    "equipment.unlink.paragrafo 2": "W ten sposób nie będzie możliwe zarządzanie ustawieniami i menu poprzez platformę, chyba że ponownie połączysz sprzęt, postępując zgodnie z instrukcjami łączenia.",
    "equipamento desvinculado com sucesso!": "Sprzęt odłączony pomyślnie!",
    "equipment.ocorreu um erro. Tente novamente mais tarde": "Wystąpił błąd. Spróbuj ponownie później",
    "equipment.sim": "Tak",
    "equipment.não": "Nie",
    "equipment.Encerrar acesso remoto": "Zakończ zdalny dostęp",
    "equipment.Horas sem limpeza": "Godziny bez czyszczenia",
    "equipment.Histórico de eventos": "Historia zdarzeń",
    "equipment.Evento": "Zdarzenie",
    "equipment.Data": "Data",
    "equipment.ERRO SENSOR RI": "Błąd z czujnikiem dolnej rezystancji",
    "equipment.ERRO COM INV. DE FREQ.": "Błąd z falownikiem częstotliwości",
    "equipment.ERRO MAGNETRON": "Błąd magnetrnou",
    "equipment.ERRO SENSOR CAMARA": "Błąd z czujnikiem kamery",
    "equipment.ERRO CAN": "Błąd CAN",
    "equipment.Erro INVERSOR DE FREQUENCIA": "Błąd falownika częstotliwości",
    "equipment.ERRO DO MODELO DO EQUIPAMENTO": "Błąd modelu sprzętu",
    "equipment.ERRO PAINEL": "Błąd panelu",
    "equipment.ERRO INV.FREQ.STATUS": "Status błędu falownika częstotliwości",
    "equipment.ERRO SENSOR BOARD": "Błąd czujnika płyty",
    "equipment.ERRO ENERGY": "Błąd energii",
    "equipment.ERRO I2C": "Błąd I2C",
    "equipment.EQUIPAMENTO LIGADO": "Sprzęt włączony",
    "equipment.EQUIPAMENTO DESLIGADO": "Sprzęt wyłączony",
    "equipment.Plataforma": "Platforma",
    "equipment.Forno": "Piekarnik",
    "equipment.Origem": "Pochodzenie",
    "equipment.Locais": "Lokalizacje",
    "equipment.não foi possível carregar as entidades": "Nie udało się załadować jednostek",
    "equipment.conceder acesso remoto a": "Przyznaj zdalny dostęp do",
    "equipment.atualizar equipamentos": "Aktualizuj sprzęt",
    "equipment.suporte": "Wsparcie",
    "equipment.PIN": "PIN",
    "equipment.nome do equipamento": "Nazwa sprzętu",
    "equipment.vinculado à loja": "Powiązane ze sklepem",
    "equipment.cadastrar loja": "Zarejestruj sklep",
    "equipment.informe o PIN do forno": "Podaj PIN do piekarnika",
    "equipment.preencha o PIN do forno": "Wypełnij pole PIN piekarnika",
    "equipment.adicione um nome para facilitar seu reconhecimento": "Dodaj nazwę, aby ułatwić rozpoznawanie",
    "equipment.preencha o nome do equipamento": "Wypełnij nazwę sprzętu",
    "equipment.selecionar": "Wybierz",
    "equipment.nome fantasia": "Nazwa fantazyjna",
    "equipment.informe o nome fantasia": "Podaj nazwę fantazyjną",
    "equipment.preencha o nome fantasia": "Wypełnij nazwę fantazyjną",
    "equipment.CNPJ": "CNPJ",
    "equipment.XX.XXX.XXX/0001.XX": "XX.XXX.XXX/0001.XX",
    "equipment.preencha o CNPJ": "Wypełnij numer CNPJ",
    "equipment.CEP": "Kod pocztowy",
    "equipment.00000-000": "00000-000",
    "equipment.preencha o CEP": "Wypełnij kod pocztowy",
    "equipment.cidade": "Miasto",
    "equipment.informe a cidade": "Podaj miasto",
    "equipment.preencha a cidade": "Wypełnij miasto",
    "equipment.UF": "Stan",
    "equipment.bairro": "Dzielnica",
    "equipment.informe o bairro": "Podaj dzielnicę",
    "equipment.endereço": "Adres",
    "equipment.informe o seu endereço": "Podaj swój adres",
    "equipment.preencha o seu endereço": "Wypełnij swoje adres",
    "equipment.Nº": "Numer",
    "equipment.equipamento cadastrado com sucesso!": "Sprzęt zarejestrowany pomyślnie!",
    "equipment.ocorreu um erro": "Wystąpił błąd",
    "equipment.preencha o modelo": "Wypełnij model",
    "equipment.selecione uma categoria": "Wybierz kategorię",
    "equipment.selecione uma loja": "Wybierz sklep",
    "equipment.preencha o bairro": "Wypełnij dzielnicę",
    "equipment.não foi possível cadastrar o equipamento. Tente novamente mais tarde": "Nie udało się zarejestrować sprzętu. Spróbuj ponownie później",
    "equipment.loja cadastrada com sucesso": "Sklep zarejestrowany pomyślnie",
    "equipment.não foi possível cadastrar a loja. Tente novamente mais tarde": "Nie udało się zarejestrować sklepu. Spróbuj ponownie później",
    "equipment.equipment": "sprzęt",
    "equipment.stores": "sklepy",
    "equipment.search equipment": "szukaj sprzętu",
    "equipment.add equipment": "dodaj sprzęt",
    "equipment.update equipment": "aktualizuj sprzęt",
    "equipment.plus equipment": "sprzęt",
    "equipment.Procurar equipamento": "Szukaj sprzętu",
    "equipment.EQUIPAMENTOS": "SPRZĘT",
    "equipment.Atualizar equipamento": "Aktualizuj sprzęt",
    "equipment.Atualizar equipamentos": "Aktualizuj sprzęt",
    "equipment.confirmar": "potwierdź",
    "equipment.Selecione os modelos que deseja atualizar:": "Wybierz modele, które chcesz zaktualizować",
    "equipment.Como deseja realizar a atualização?": "Jak chcesz postępować z aktualizacją?",
    "equipment.Enviar atualização pela plataforma (wi-fi)": "Wyślij aktualizację przez platformę (wi-fi)",
    "equipment.Salvar arquivo de atualização em pen drive": "Zapisz plik aktualizacji na pendrive USB",
    "equipment.Linha Express": "Linia ekspresowa",
    "equipment.Escolha como deseja realizar a atualização": "Wybierz, jak chcesz postępować z aktualizacją",
    "equipment.Selecione ao menos um modelo de equipamento para atualizar": "Wybierz co najmniej jeden model sprzętu do zaktualizowania",
    "equipment.Status:": "Status: ",
    "equipment.Número de série:": "Numer seryjny",
    "equipment.Menu:": "Menu",
    "equipment.Loja:": "Sklep",
    "equipment.End:": "Zakończ: ",
    "equipment.Cidade:": "Miasto: ",
    "equipment.CEP:": "Kod pocztowy: ",
    "equipment.Atualização de software enviada com sucesso!": "Aktualizacja oprogramowania wysłana pomyślnie!",
    "equipment.Não foi possível enviar a atualização de sodtware. Tente novamente mais tarde": "Nie udało się wysłać aktualizacji oprogramowania. Spróbuj ponownie później",
    "equipment.Download realizado com sucesso": "Pobieranie zakończone pomyślnie",
    "equipment.Não foi possível realizar o download. Tente novamente mais tarde": "Nie udało się ukończyć pobierania. Spróbuj ponownie później",
    "equipment.name": "Nazwa",
    "equipment.model": "Model",
    "equipment.category": "Kategoria",
    "equipment.store": "sklep",
    "FIT Express/FIT ST": "FIT Express/FIT ST",
    "Copa/Single Mag/Rocket": "Copa/Single Mag/Rocket",
    "Forza STi": "Forza STi",
    "TSi": "TSi",
    "FIT Express": "FIT Express",
    "FIT ST": "FIT ST",
    "equipment.Preencha o nome da versão": "Podaj nazwę wersji",
    "equipment.Informe aqui todas as alterações desta nova versão": "Wprowadź wszystkie zmiany w tej nowej wersji",
    "equipment.Nova versão de software atualizada com sucesso!": "Nowa wersja oprogramowania zaktualizowana pomyślnie!",
    "equipment.Informe pelo menos uma mudança nesta versão": "Wprowadź co najmniej jedną zmianę w tej wersji",
    "equipment.Falha ao ler o arquivo": "Nie udało się odczytać pliku",
    "equipment.selecionar tipo": "wybierz typ",
    "equipment.selecione o tipo da versão": "wybierz typ wersji",
    "equipment.atualização": "AKTUALIZACJA",
    "equipment.Selecione abaixo o que deseja fazer:": "Wybierz poniżej, co chcesz zrobić:",
    "equipment.Atualizar meus equipamentos": "Zaktualizuj mój sprzęt",
    "equipment.Enviar atualização para usuários selecionados": "Wyślij aktualizację do wybranych użytkowników",
    "equipment.ENVIAR": "WYŚLIJ",
    "equipment.ENVIAR ATUALIZAÇÃO": "WYŚLIJ AKTUALIZACJĘ",
    "equipment.Selecione os e-mails para os quais deseja enviar a atualização:": "Wybierz adresy e-mail, do których chcesz wysłać aktualizację:",
    "equipment.A atualização disponibilizada no dia": "Dostępna aktualizacja w dniu",
    "equipment.referente ao equipamento": "dotyczące sprzętu",
    "equipment.contempla o seguinte:": "rozważ następujące:",
    "equipment.Selecionar arquivo para envio": "Wybierz plik do wysłania",
    "equipment.Por favor, selecione um arquivo .tar": "Proszę wybrać plik .tar",
    "equipment.Selecione o modelo do equipamento:": "Wybierz model sprzętu:",
    "equipment.Selecione uma opção": "Wybierz opcję",
    "equipment.Nomeie a versão": "Nadaj nazwę wersji",
    "equipment.Clique sobre o arquivo que deseja baixar:": "Kliknij plik, który chcesz pobrać:",
    "equipment.Baixar atualização": "Pobierz aktualizację",
    "equipment.Versões:": "Wersje:",
    "equipment.e superiores": "i wyżej",
    "FIT Express, FIT ST": "FIT Express, FIT ST",
    "Copa Express, Rocket Express, Copa Single Mag": "Copa Express, Rocket Express, Copa Single Mag",
    "equipment.e": "i",
    "equipment.Últimas versões de software:": "Najnowsze wersje oprogramowania: ",
    "equipment.Você não possui nenhum equipamento desatualizado": "Nie masz przestarzałego sprzętu",
    "equipment.grupos": "grupy",
    "equipment.não foi possível carregar os grupos de equipamentos da companhia": "Nie można załadować grup sprzętu firmy",
    "equipment.vinculado ao grupo": "Połączony z grupą",
    "equipment.selecione um grupo": "Wybierz grupę",
    "equipment.cadastrar grupo": "Zarejestruj grupę",
    "equipment.Número de Série": "Numer seryjny",
    "equipment.Versão": "Wersja",
    "equipment.não foi possível cadastrar o equipamento. Selecione ou cadastre um grupo": "Nie udało się zarejestrować sprzętu. Wybierz lub zarejestruj grupę",
    "equipment.CRIE GRUPOS DE EQUIPAMENTOS!": "TWÓRZ GRUPY SPRZĘTU!",
    "equipment.Para que os usuários vinculados à conta possam visualizar os equipamentos você precisa:": "Aby użytkownicy powiązani z kontem mogli widzieć sprzęt, musisz:",
    "equipment.1) Criar grupo(s);": "1) Tworzyć grupy;",
    "equipment.2) Atribuir equipamento(s) ao grupo;": "2) Przypisywać sprzęt do grupy;",
    "equipment.3) Atribuir usuário(s) ao grupo.": "3) Przypisywać użytkowników do grupy.",
    "equipment.Status software": "Status oprogramowania",
    "equipment.Status menu": "Status menu",
    "equipment.Pendente": "Oczekujący",
    "equipment.Aceito": "Zaakceptowany",
    "equipment.Recusado": "Odrzucony",
    "equipment.Atualizar": "Aktualizacja",
    "equipment.Deseja enviar a atualização para o equipamento selecionado?": "Czy chcesz wysłać aktualizację do wybranej jednostki?",
    "equipment.Companhia": "Firma",
    "equipment.Exportar menu": "Eksportuj menu",
    "equipment.companhias": "firmy",
    "equipment.Nome da companhia": "Nazwa firmy",
    "equipment.Procurar equipamento, usuário ou companhia": "Wyszukaj sprzęt, użytkownika lub firmę",
    "equipment.Versão da power": "Wersja mocy",
    "equipment.AINDA NÃO HÁ DADOS DE RECEITAS AQUI :(": "NIE MA JESZCZE DANYCH PRZEPISU :(",
    "equipment.AINDA NÃO HÁ DADOS DE LIMPEZAS AQUI :(": "NIE MA TUTAJ DANYCH DO CZYSZCZENIA :(",
    "equipment.AINDA NÃO HÁ DADOS DE EVENTOS AQUI :(": "NIE MA TUTAJ DANYCH O WYDARZENIU :(",
    "ID": "ID",
    "equipment.Hora": "Godzina",
    "equipment.EVENTOS MAIS OCORRIDOS NO EQUIPAMENTO DURANTE O PERÍODO": "NAJCZĘŚCIEJ WYSTĘPUJĄCE ZDARZENIA NA SPRZĘCIE W OKRESIE",
    "equipment.hour": "G",
    "equipment.minutes": "Min",
    "equipment.date format": "DD/MM/RRRR",
    "equipment.preparada": "przygotowana",
    "equipment.vezes": "razy",
    "equipment.vez": "raz",
    "equipment.Ver": "Zobacz",
    "equipment.Os equipamentos enviarão os dados à plataforma ao serem ligados. A cada dois meses, os dados recebidos serão automaticamente excluídos.": "Po włączeniu sprzęt wyśle ​​dane na platformę. Co dwa miesiące otrzymane dane będą automatycznie usuwane.",
    "equipment.Ligado": "Włączony",
    "equipment.Desligado": "Wyłączony",
    "equipment.CAN ERROR": "Błąd CAN",
    "equipment.CAMERA SENSOR ERROR": "Błąd czujnika kamery",
    "equipment.STEAM/RI SENSOR ERROR": "Błąd czujnika RI",
    "equipment.PROBE 1 ERROR": "Alarm sondy 1",
    "equipment.PROBE 2 ERROR": "Alarm sondy 2",
    "equipment.PROBE 3 ERROR": "Alarm sondy 3",
    "equipment.PROBE 4 ERROR": "Alarm sondy 4",
    "equipment.PROBE 5 ERROR": "Alarm sondy 5",
    "equipment.PROBE 6 ERROR": "Alarm sondy 6",
    "equipment.GENERAL PROBE ERROR": "Ogólny błąd sondy",
    "equipment.WATER LACK ERROR": "Alarm braku wody",
    "equipment.HIGH TEMPERATURE PANEL ERROR": "Alarm wysokiej temperatury na desce rozdzielczej",
    "equipment.FREQUENCY INVERTER GENERAL ERROR": "Błąd przetwornicy częstotliwości",
    "equipment.FREQUENCY INVERTER COMMUNICATION ERROR": "Alarm komunikacji w przetwornicy częstotliwości",
    "equipment.FREQUENCY INVERTER STATUS ERROR": "Alarm stanu przetwornicy częstotliwości",
    "equipment.CLEANING NOT COMPLETED ERROR": "Alarm niekompletnego czyszczenia",
    "equipment.MAGNETRON ERROR": "Alarm magnetronu",
    "equipment.EQUIPMENT FAMILY/MODEL ERROR": "Błąd modelu urządzenia",
    "equipment.BOARD TEMPERATURE SENSOR ERROR": "Alarm czujnika temperatury płyty",
    "equipment.ENERGY MODULE ERROR": "Błąd modułu energetycznego",
    "equipment.POWER BOARD I2C BUS ERROR": "Błąd płyty zasilającej",
    "equipment.GAS SYSTEM ERROR": "Błąd gazu",
    "equipment.VERY HIGH PANEL TEMPERATURE ERROR": "Błąd bardzo wysokiej temperatury panelu",
    "equipment.POWER ON": "Włączony",
    "equipment.POWER OFF": "Wyłączony",
    "equipment.DOOR OPENED": "Drzwi otwarte",
    "equipment.DOOR CLOSED": "Drzwi zamknięte",
    "equipment.CLEANING CANCELED ERROR": "Odkażanie anulowane przez użytkownika",
    "equipment.PIN não encontrado. Por favor, verifique e tente novamente": "Nie znaleziono kodu PIN. Sprawdź i spróbuj ponownie",
    "equipment.Online": "Online",
    "equipment.Offline": "Offline",
    "equipment.Exibir apenas eventos": "Pokaż tylko wydarzenia",
    "equipment.Endereço": "Adres",
    "equipment.EQUIPMENT CONNECTED TO THE PLATFORM": "Sprzęt podłączony do platformy",
    "equipment.EQUIPMENT DISCONNECTED FROM THE PLATFORM": "Sprzęt odłączony od platformy",
    "equipment.MENU RECEIVED FROM THE PLATFORM": "Menu otrzymane z platformy",
    "equipment.MENU REJECTED BY THE USER": "Menu odrzucone przez użytkownika",
    "equipment.CONNECTED TO THE INTERNET": "Podłączony do internetu",
    "equipment.DISCONNECTED FROM THE INTERNET": "Odłączono od Internetu",
    "equipment.Infelizmente não há versões que realizem o envio de dados compatíveis com este equipamento.": "Niestety, nie ma wersji, które wykonują przesyłanie danych zgodne z tym urządzeniem.",
    "equipment.O equipamento": "Urządzenie",
    "equipment.deve ser atualizado para a versão mais recente de software para que passe a enviar os dados para a plataforma": "musi zostać zaktualizowane do najnowszej wersji oprogramowania, aby zaczęło przesyłać dane na platformę",
    "equipment.Versão do seu equipamento": "Wersja twojego urządzenia",
    "equipment.Versão disponível": "Dostępna wersja",
    "equipment.Selecione abaixo como deseja enviar a atualização para seu equipamento": "Wybierz poniżej, jak chcesz wysłać aktualizację do swojego urządzenia",
    "equipment.Salvar em pen drive": "Zapisz na pendrive",
    "equipment.Enviar por wi-fi": "Wyślij przez Wi-Fi",
    "equipment.Cliente": "Klient",
    "equipment.NÚMERO DE SÉRIE NÃO ENCONTRADO": "NIE ZNALEZIONO NUMERU SERYJNEGO",
    "equipment.BUSCAR": "SZUKAJ",
    "equipment.ACESSADOS RECENTEMENTE": "OSTATNI DOSTĘP",
    "equipment.SEM BUSCAS RECENTES": "BRAK OSTATNICH WYSZUKIWAŃ",
    "equipment.Procurar equipamento por NS": "Wyszukaj sprzęt po NS",
    "equipment.AIR FILTER CLEANING COMPLETED": "Czyszczenie filtra powietrza zakończone",
    "equipment.AIR FILTER CLEANING REJECTED": "Odmowa czyszczenia filtra powietrza",
    "equipment.CATALYTIC FILTER CLEANING COMPLETED": "Zakończono czyszczenie filtra katalitycznego",
    "equipment.CATALYTIC FILTER CLEANING REJECTED": "Odrzucono czyszczenie filtra katalitycznego",
    "equipment.ID do equipamento": "ID urządzenia",
    "equipment.Todo equipamento deve ser vinculado a um grupo. Selecione um grupo da lista ou cadastre um novo clicando em + Cadastrar grupo.": "Cały sprzęt musi być połączony z grupą. Wybierz grupę z listy lub zarejestruj nową klikając + Zarejestruj grupę.",
    "equipment.Nomeie seu forno": "Nazwij swój piekarnik",
    "equipment.Para visualizar o PIN, acesse nas configurações do equipamento a Plataforma Virtual IOK.": "Aby zobaczyć PIN, należy wejść do Wirtualnej Platformy IOK w ustawieniach sprzętu.",
    "equipment.localização": "Lokalizacja",
    "equipment.AINDA NÃO HÁ NENHUMA LOCALIZAÇÃO CADASTRADA :(": "NIE MA JESZCZE ZAREJESTROWANEJ LOKALIZACJI :(",
    "equipment.Procurar empresa ou local": "Wyszukaj firmę lub lokalizację",
    "equipment.Adicionar localização": "Dodaj miejsce",
    "equipment.espaço": "Przestrzeń",
    "equipment.local": "Lokalny",
    "equipment.EXCLUIR LOCALIZAÇÃO": "USUŃ LOKALIZACJĘ",
    "equipment.Você deseja excluir esta localização?": "Czy chcesz usunąć tę lokalizację?",
    "equipment.Ao fazer isso, os equipamentos atrelados à ela não exibirão nenhuma localização, sendo necessário informar um novo local para eles.": "Gdy to zrobisz, powiązane z nim urządzenia nie będą wyświetlać żadnej lokalizacji, co spowoduje konieczność zapewnienia dla nich nowej lokalizacji.",
    "equipment.Localização deletada com sucesso!": "Lokalizacja została pomyślnie usunięta!",
    "equipment.Erro ao deletar a localização. Tente novamente mais tarde": "Błąd podczas usuwania lokalizacji. Spróbuj ponownie później",
    "equipment.Não foi possivel deletar a localização": "Nie można usunąć lokalizacji",
    "equipment.Localização adicionada com sucesso!": "Lokalizacja została dodana pomyślnie!",
    "equipment.Não foi possível adicionar a localização. Tente novamente mais tarde": "Nie można dodać lokalizacji. Spróbuj ponownie później",
    "equipment.Nome do espaço": "Nazwa kosmiczna",
    "equipment.Informe o nome do espaço": "Wpisz nazwę przestrzeni",
    "equipment.Preencha o nome do espaço": "Wpisz nazwę przestrzeni",
    "equipment.país": "Kraj",
    "equipment.Endereço (rua, avenida, rodovia)": "Adres (ulica, aleja, autostrada)",
    "equipment.Nº/Km": "Nr/Km",
    "equipment.informe o país": "Wprowadź kraj",
    "equipment.preencha o país": "Wpisz kraj",
    "equipment.Localização editada com sucesso!": "Lokalizacja została edytowana pomyślnie!",
    "equipment.Não foi possível editar a localização. Tente novamente mais tarde": "Nie można edytować lokalizacji. Spróbuj ponownie później",
    "equipment.Editar localização": "Edytuj lokalizację",
    "equipment.Pico de preparações": "Szczyt przygotowań",
    "equipment.informações da semana": "informacja tygodnia",
    "equipment.horários de pico": "godziny szczytu",
    "equipment.horário de pico": "czas szczytu",
    "equipment.preparações do dia": "przygotowania dnia",
    "equipment.Horário": "Czas",
    "equipment.Preparações": "Przygotowania",
    "equipment.dia selecionado": "wybrany dzień",
    "equipment.Vinculação IOK": "Wiązanie IOK",
    "equipment.Última preparação": "Ostatnie przygotowanie",
    "equipment.A limpeza adequada e diária do forno auxilia em seu funcionamento correto e impacta na garantia.": "Prawidłowe i codzienne czyszczenie piekarnika pomaga w jego prawidłowym funkcjonowaniu i ma wpływ na gwarancję.",
    "equipment.Total de limpezas realizadas:": "Całkowite czyszczenie:",
    "equipment.Limpezas não concluídas:": "Czyszczenie nie zostało zakończone:",
    "equipment.Desde a vinculação do forno a IOK": "Od podłączenia piekarnika do IOK",
    "equipment.Equipamento ligado há:": " Sprzęt włączony od:",
    "equipment.Total de receitas preparadas:": "Łączna liczba przygotowanych przepisów:",
    "equipment.Receitas preparadas": "Przygotowane przepisy",
    "equipment.Aberturas de porta": "Otwory drzwiowe",
    "equipment.Desde a última vez que o forno foi ligado": "Od ostatniego włączenia piekarnika",
    "equipment.Preparação": "Przygotowanie",
    "equipment.Nenhuma localização criada": "Nie utworzono żadnych lokalizacji",
    "equipment.Acesso remoto": "Zdalny dostęp",
    "equipment.Informe o ID da empresa que poderá acessar remotamente seu forno. Use ; para separar IDs.": "Wprowadź identyfikator firmy, która może uzyskać zdalny dostęp do Twojego piekarnika. Używać ; do oddzielenia identyfikatorów.",
    "equipment.O acesso remoto permite que as empresas selecionadas enviem menus e atualizações para seu equipamento.": "Zdalny dostęp umożliwia wybranym firmom wysyłanie menu i aktualizacji do Twojego sprzętu.",
    "equipment.Concedo acesso remoto à Prática oficial.": "Udzielam zdalnego dostępu do oficjalnej Prática.",
    "equipment.Deseja selecionar outros equipamentos vinculados à sua conta para conceder acesso remoto a eles?": "Czy chcesz wybrać inne urządzenia powiązane z Twoim kontem i przyznać im zdalny dostęp?",
    "equipment.Selecione abaixo os equipamentos para os quais deseja conceder acesso remoto à": "Wybierz poniżej sprzęt, do którego chcesz przyznać zdalny dostęp",
    "equipment.Acesso remoto concedido com sucesso!": "Zdalny dostęp został przyznany pomyślnie!",
    "equipment.Selecione pelo menos um equipamento": "Wybierz co najmniej jedno urządzenie",
    "equipment.Não foi possível editar os equipamentos. Tente novamente mais tarde": "Nie można edytować wyposażenia. Spróbuj ponownie później",
    "equipment.Solicitar atendimento": "Zapytaj o usługę",
    "equipment.Relate um problema": "Zgłoś problem",
    "equipment.Descreva aqui o problema de seu equipamento": "Opisz tutaj swój problem ze sprzętem",
    "equipment.Não foi possível enviar sua solicitação de atendimento. Tente mais tarde": "Nie mogliśmy wysłać Twojego zgłoszenia serwisowego. Spróbuj później",
    "equipment.Solicitação técnica enviada com sucesso!": "Zapytanie techniczne zostało wysłane pomyślnie!",
    "equipment.Enviar": "Potwierdzać",
    "equipment.Solicitação de atendimento": "Zapytanie serwisowe",
    "equipment.Informações do magnetron": "Informacje o magnetronie",
    "equipment.Vida útil aproximada": "Szacowana żywotność",
    "equipment.MAGNETRON USAGE HOURS": "Godziny pracy magnetronu",
    "equipment.Atendimento": "Praca",
    "equipment.Eventos mais ocorridos no equipamento durante o período": "Zdarzenia najczęściej występujące na sprzęcie w danym okresie",
    "equipment.EQUIPAMENTO SEM NÚMERO DE SÉRIE": "SPRZĘT BEZ NUMERU SERYJNEGO",
    "equipment.Filtros": "Filtry",
    "equipment.Filtrar": "Filtrować",
    "equipment.Limpar filtros": "Wyczyść filtry",
    "equipment.Procurar por modelo, versão ou país": "Wyszukaj według modelu, wersji lub kraju",
    "equipment.Procurar equipamento, NS, modelo, versão ou país": "Wyszukaj urządzenie, NS, model, wersję lub kraj",
    "equipment.Os seguintes IDs não pertencem a uma companhia do tipo 'Distribuidor' e foram removidos": "Następujące ID nie należą do firmy typu 'Dystrybutor' i zostały usunięte",
    "equipment.BAIXAR VERSÕES": "POBIERZ WERSJE",
    "equipment.Compatível com todas as versões da IHM.": "Kompatybilny ze wszystkimi wersjami HMI.",
    "equipment.Compatível apenas com a versão 2.0.0C da Power.": "Kompatybilny tylko z wersją Power 2.0.0C.",
    "equipment.Procurar evento" : "Szukaj wydarzenia",
    "equipment.Forno conectado": "Piekarnik połączony",
    "equipment.Limpeza de filtro de ar": "Czyszczenie filtra powietrza",
    "equipment.Limpeza de filtro catalítico": "Czyszczenie filtra katalitycznego",
    "equipment.Forno ligado": "Piekarnik włączony",
    "equipment.Inversor de frequência": "Przetwornica częstotliwości",
    "equipment.Temperatura de painel": "Temperatura panelu",
    "equipment.Sensor da câmara": "Czujnik komory",
    "equipment.Magnetron": "Magnetron",
    "equipment.Power": "Power",
    "equipment.Informações de menu": "Informacje o menu",
    "equipment.Limpeza": "Czyszczenie",

    //LOJAS
    "store.Procurar loja": "Wyszukaj sklep",
    "store.Excluir": "Usuń",
    "store.Editar": "Edytuj",
    "store.nome": "Nazwa",
    "store.equipamentos": "Sprzęt",
    "store.cidade": "Miasto",
    "store.estado": "Stan",
    "store.salvar": "Zapisz",
    "store.cadastrar loja": "zarejestruj sklep",
    "store.Nome fantasia": "Nazwa fantazyjna",
    "store.Preencha o nome da loja": "Wypełnij nazwę sklepu",
    "store.CNPJ": "CNPJ",
    "store.Preencha o CNPJ da loja": "Wypełnij numer CNPJ sklepu",
    "store.CEP": "Kod pocztowy",
    "store.Preencha o CEP da loja": "Wypełnij kod pocztowy sklepu",
    "store.Informe o nome fantasia": "Wypełnij nazwę fantazyjną",
    "store.Cidade": "Miasto",
    "store.Informe a cidade": "Podaj miasto",
    "store.Preencha a cidade": "Wypełnij miasto",
    "store.UF": "Stan",
    "store.Preencha o UF da loja": "Wypełnij stan sklepu",
    "store.Bairro": "Dzielnica",
    "store.Informe o bairro": "Podaj dzielnicę",
    "store.Preencha o bairro da loja": "Wypełnij dzielnicę sklepu",
    "store.Endereço": "Adres",
    "store.Informe o endereço": "Podaj adres",
    "store.Preencha o endereço da loja": "Wypełnij adres sklepu",
    "store.Nº": "Numer",
    "store.Loja cadastrada com sucesso": "Sklep zarejestrowany pomyślnie",
    "store.Erro ao adicionar loja. Tente novamente": "Błąd podczas dodawania sklepu. Spróbuj ponownie",
    "store.Loja deletada com sucesso": "Sklep usunięty pomyślnie",
    "store.Erro ao deletar loja. Tente novamente mais tarde": "Błąd podczas usuwania sklepu. Spróbuj ponownie później",
    "store.AINDA NÃO HÁ NENHUMA LOJA CADASTRADA :(": "NIE MA JESZCZE ZAREJESTROWANYCH SKLEPÓW :(",
    "store.EXCLUIR LOJA": "USUŃ SKLEP",
    "store.Você deseja excluir a loja da plataforma IOK?": "Czy chcesz usunąć sklep z platformy IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ela não terão vínculo com nenhuma loja, a menos que os vincule novamente.": "W ten sposób sprzęt z nim powiązany nie będzie powiązany z żadnym sklepem, chyba że ponownie je połączysz.",
    "store.loja editada com sucesso": "Sklep edytowany pomyślnie",
    "store.não foi possível editar a loja. Tente novamente mais tarde": "Nie udało się edytować sklepu. Spróbuj ponownie później",
    "store.editar loja": "Edytuj sklep",
    "store.Procurar grupo de equipamentos": "Wyszukaj grupę sprzętu",
    "store.cadastrar grupo": "zarejestruj grupę",
    "store.AINDA NÃO HÁ NENHUM GRUPO CADASTRADO :(": "NIE MA JESZCZE ZAREJESTROWANEJ GRUPY :(",
    "store.cadastrar grupo de equipamentos": "zarejestruj grupę sprzętu",
    "store.grupo de equipamentos cadastrado com sucesso!": "Grupa sprzętu została pomyślnie zarejestrowana!",
    "store.não foi possível cadastrar o grupo. Tente novamente mais tarde": "Nie można zarejestrować grupy. Spróbuj ponownie później",
    "store.Nome do grupo": "Nazwa grupy",
    "store.informe o nome do grupo": "Wprowadź nazwę grupy",
    "store.preencha o nome do grupo": "Wpisz nazwę grupy",
    "store.Grupo de equipamentos deletado com sucesso!": "Grupa sprzętu została pomyślnie usunięta!",
    "store.Erro ao deletar o grupo. Tente novamente mais tarde": "Błąd podczas usuwania grupy. Spróbuj ponownie później",
    "store.EXCLUIR GRUPO DE EQUIPAMENTOS": "USUŃ GRUPĘ SPRZĘTU",
    "store.Você deseja excluir este grupo de equipamentos da plataforma IOK?": "Czy chcesz usunąć tę grupę sprzętu z platformy IOK?",
    "store.Ao fazer isso, os equipamentos atrelados à ele não terão vínculo com nenhum grupo, a menos que os vincule novamente.": "Gdy to zrobisz, powiązane z nim wyposażenie nie zostanie połączone z żadną grupą, chyba że połączysz je ponownie.",
    "store.grupo de equipamentos editado com sucesso!": "Grupa sprzętu została pomyślnie zmodyfikowana!",
    "store.não foi possível editar o grupo. Tente novamente mais tarde": "Nie można edytować grupy. Spróbuj ponownie później",
    "store.editar grupo de equipamentos": "edytować grupę sprzętu",
    "store.CEP não encontrado no Brasil": "Nie znaleziono kodu pocztowego w Brazylii",

    //NAVBAR
    "navbar.home": "strona główna",
    "navbar.cookbook": "książka kucharska",
    "navbar.my menus": "moje menu",
    "navbar.equipment": "sprzęt",
    "navbar.users": "użytkownicy",
    "navbar.calls": "usługi",
    "navbar.settings": "ustawienia",
    "navbar.help": "pomoc",
    "navbar.logout": "wyloguj",
    "navbar.Editar conta": "Edytuj konto",
    "navbar.API Externa": "Zewnętrzne API",

    //FOOTERBAR
    "footerBar.menu": "Menu",
    "footerBar.cookbook": "Książka kucharska",
    "footerBar.home": "Strona główna",
    "footerBar.menus": "Menu",
    "footerBar.notifications": "Powiadomienia",

    //ATENDIMENTOS
    "calls.ATENDIMENTOS EM ABERTO": "TRWAJĄCE USŁUGI",
    "calls.ATENDIMENTOS ENCERRADOS": "ZAKOŃCZONE USŁUGI",

    //MAPA
    "map.título": "Lokalizacja",
    "map.erro": "Błąd",
    "map.mensagem de erro": "Nie można wczytać mapy",
    "map.Localização não disponível": "Lokalizacja niedostępna",

    //MODAL
    "modal.CONFIRMAR": "POTWIERDŹ",
    "modal.Salvar alterações": "Zapisz edycje",
    "Modal.Há alterações não salvas nos parâmetros dos passos": "Istnieją niezapisane zmiany w parametrach kroku",
    "Modal.Como deseja proceder?": "Jak chcesz postąpić?",
    "Modal.Avaliar alterações realizadas": "Ocen zmiany",
    "Modal.Descartar alterações": "Odrzuć zmiany",

    //USUÁRIOS
    "user.Nome": "Imię",
    "user.E-mail": "E-mail",
    "user.Permissão": "Uprawnienia",
    "user.Loja": "Sklep",
    "user.Status": "Status",
    "user.Excluir": "Usuń",
    "user.Editar": "Edytuj",
    "user.Procurar usuário": "Szukaj użytkownika",
    "user.ADICIONAR USUÁRIO": "DODAJ UŻYTKOWNIKA",
    "user.AINDA NÃO HÁ NENHUM USUÁRIO CADASTRADO :(": "JESZCZE NIE ZAREJESTROWANO ŻADNYCH UŻYTKOWNIKÓW :(",
    "user.+ Usuário": "+ Użytkownik",
    "user.Informe o nome do usuário": "Podaj nazwę użytkownika",
    "user.Preencha o nome do usuário": "Wypełnij nazwę użytkownika",
    "user.Informe o e-mail do usuário": "Podaj adres e-mail użytkownika",
    "user.Preencha com um e-mail válido": "Podaj poprawny adres e-mail",
    "user.Administrador": "Administrator",
    "user.Usuário": "Użytkownik",
    "user.Preencha o telefone do usuário": "Podaj numer telefonu użytkownika",
    "user.Selecione a permissão do usuário": "Wybierz uprawnienia użytkownika",
    "user.Ativo": "Aktywny",
    "user.Inativo": "Nieaktywny",
    "user.Usuário deletado da plataforma com sucesso!": "Użytkownik pomyślnie usunięty z platformy!",
    "user.Ocorreu um erro. Tente novamente mais tarde": "Wystąpił błąd. Spróbuj ponownie później",
    "user.EXCLUIR USUÁRIO": "USUŃ UŻYTKOWNIKA",
    "user.delete.paragrafo 1": "Czy chcesz usunąć użytkownika z platformy IOK?",
    "user.delete.paragrafo 2": "W ten sposób straci on dostęp do wszystkich narzędzi platformy, chyba że zostanie ponownie dodany, postępując zgodnie z instrukcjami rejestracji.",
    "user.EDITAR USUÁRIO": "EDYTUJ UŻYTKOWNIKA",
    "user.Usuário editado com sucesso!": "Użytkownik pomyślnie edytowany!",
    "user.Não foi possível editar este usuário. Por favor, tente mais tarde": "Nie udało się edytować tego użytkownika. Spróbuj ponownie później",
    "user.INFORMAÇÕES DO USUÁRIO": "INFORMACJE O UŻYTKOWNIKU",
    "user.Telefone": "Numer telefonu",
    "user.Não foi possível cadastrar este usuário. Por favor, tente mais tarde": "Nie udało się zarejestrować tego użytkownika. Spróbuj ponownie później",
    "user.Usuário adicionado com sucesso!": "Użytkownik dodany pomyślnie!",
    "user.CRIAR TIPO DE USUÁRIO": "UTWÓRZ RODZAJ UŻYTKOWNIKA",
    "user.Criar novo tipo de usuário": "Utwórz nowy typ użytkownika",
    "user.Adicionar novo usuário": "Dodaj nowego użytkownika",
    "user.Permissões": "Uprawnienia",
    "user.Preencha o nome do novo tipo de usuário": "Wypełnij nazwę nowego rodzaju użytkownika",
    "user.Selecione pelo menos uma permissão": "Wybierz co najmniej jedno uprawnienie",
    "user.Não foi possível carregar as permissões": "Nie udało się załadować uprawnień",
    "user.Tipo de usuário criado com sucesso!": "Rodzaj użytkownika utworzony pomyślnie!",
    "user.Não foi possível criar este novo tipo de usuário. Por favor, tente mais tarde": "Nie udało się utworzyć tego nowego rodzaju użytkownika. Spróbuj ponownie później",
    "user.Não foi possível carregar os tipos de usuário da companhia": "Nie udało się załadować rodzajów użytkowników firmy",
    "user.Tipo de usuário": "Rodzaj użytkownika",
    "user.E-mail já cadastrado na plataforma. Informe um novo e-mail": "E-mail już zarejestrowany na platformie. Podaj nowy adres e-mail",
    "user.UNIR CONTAS": "POŁĄCZ KONTA",
    "user.ID da companhia": "Identyfikator firmy",
    "user.Digite o ID da companhia que deseja unir a sua conta": "Wpisz identyfikator firmy, z którą chcesz połączyć swoje konto",
    "user.Preencha o ID da companhia": "Wypełnij identyfikator firmy",
    "user.O administrador da conta com a qual deseja se unir poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.":
      "Administrator konta, do którego chcesz dołączyć, będzie mógł znaleźć identyfikator klikając na Twoją nazwę użytkownika, znajdującą się w górnym menu, w prawym rogu ekranu.",
    "user.O administrador da outra conta receberá um e-mail para aceitar sua solicitação. Assim que for aceita, a união das duas contas será realizada!":
      "Administrator drugiego konta otrzyma wiadomość e-mail z prośbą o zaakceptowanie Twojej prośby. Po zaakceptowaniu oba konta zostaną połączone!",
    "user.Não foi possível solicitar a união de contas. Por favor, confirme o ID ou tente mais tarde": "Nie można poprosić o połączenie kont. Potwierdź identyfikator lub spróbuj później",
    "user.SOLICITAÇÃO APROVADA COM SUCESSO": "WNIOSEK POMYŚLNIE ZATWIERDZONY",
    "user.AGUARDE ENQUANTO REALIZAMOS A UNIÃO DAS CONTAS. ESTE PROCESSO PODE LEVAR ALGUNS MINUTOS...": "PROSZĘ CZEKAĆ, aż PODŁĄCZymy KONTA. PROCES TEN MOŻE ZAJĄĆ KILKA MINUT...",
    "user.UNIÃO DE CONTAS FINALIZADA COM SUCESSO! CLIQUE NO BOTÃO ABAIXO PARA ACESSAR A PLATAFORMA.": "UNIA KONT ZAKOŃCZONA POMYŚLNIE! ABY WEJŚĆ NA PLATFORMĘ, KLIKNIJ PRZYCISK PONIŻEJ.",
    "user.NÃO CONSEGUIMOS REALIZAR A UNIÃO DAS CONTAS. POR FAVOR, TENTE NOVAMENTE. SE O PROBLEMA PERSISTIR, ENTRE EM CONTATO COM NOSSO SUPORTE.":
      "NIE MAMY MOŻLIWOŚCI ŁĄCZENIA KONT. PROSZĘ SPRÓBUJ PONOWNIE. JEŚLI PROBLEM W dalszym ciągu występuje, PROSIMY O KONTAKT Z NASZYM WSPARCIEM.",
    "user.possui todos os acessos": "ma pełny dostęp",
    "user.possui o acesso das abas cookbook, meus menus e equipamentos": "ma dostęp do książki kucharskiej, moich menu i zakładek wyposażenia",
    "user.possui apenas o acesso da aba cookbook": "ma dostęp tylko do zakładki Książka kucharska",
    "user.possui apenas o acesso da aba equipamentos": "ma dostęp tylko do zakładki wyposażenia",
    "user.possui apenas o acesso da aba meus menus": "ma dostęp tylko do karty Moje menu",
    "user.Grupo de equipamentos": "Grupa sprzętu",
    "user.Informe o telefone do usuário": "Wprowadź numer telefonu użytkownika",
    "user.Nenhum grupo criado": "Nie utworzono żadnych grup",
    "user.Digite o ID da companhia que terá acesso à sua conta": "Wpisz identyfikator firmy, która będzie miała dostęp do Twojego konta",
    "user.Ao informar um ID para unir contas com sua companhia, esteja ciente de que o novo usuário também se tornará Administrador da conta de sua companhia. É importante informar que a partir do momento em que um usuário aceitar unir contas com sua companhia, a companhia pertencente a ele deixará de existir.":
      "Podając identyfikator do łączenia kont z Twoją firmą miej świadomość, że nowy użytkownik stanie się jednocześnie Administratorem konta Twojej firmy. Warto poinformować, że z chwilą wyrażenia przez użytkownika zgody na dołączenie kont w Twojej firmie, należąca do niego firma przestanie istnieć.",
    "user.Exemplo: Sua conta: 001 | Outra conta: 111. Depois de unir contas com você, a outra conta passará a ser 001.": "Przykład: Twoje konto: 001 | Inne konto: 111. Po połączeniu kont drugie konto otrzyma numer 001.",
    "user.O administrador da outra conta poderá localizar o ID ao clicar em seu nome de usuário, localizado no menu superior, no canto direito da tela.": "Administrator drugiego konta będzie mógł zlokalizować identyfikator klikając na Twoją nazwę użytkownika, znajdującą się w górnym menu, w prawym rogu ekranu.",

    //TUTORIAIS
    "tutorial.Fechar": "Zakończ",
    "tutorial.Fim": "Koniec",
    "tutorial.Próximo": "Następny",
    "tutorial.Abrir": "Otwarty",
    "tutorial.Pular tutorial": "Pomiń tutorial",
    "tutorial.Editar detalhes da receita": "Edytuj szczegóły przepisu",
    "tutorial.Agora você pode adicionar mais detalhes às suas receitas e deixá-las ainda mais completas. Clique em Editar detalhes e preencha as informações necessárias.": "Teraz możesz dodać więcej szczegółów do swoich przepisów i uczynić je jeszcze pełniejszymi. Kliknij Edytuj szczegóły i podaj wymagane informacje.",
    "tutorial.Salvar edição da receita": "Zapisz edycję przepisu",
    "tutorial.Depois de informar mais detalhes da receita, clique em Salvar.": "Po wprowadzeniu dalszych szczegółów przepisu kliknij Zapisz.",
    "tutorial.Editar registro de um equipamento": "Edytuj rejestrację sprzętu",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Categoria e Loja.": "Aby dokończyć rejestrację swojego sprzętu, otwórz menu boczne, kliknij Edytuj i uzupełnij informacje o kategorii i sklepie.",
    "tutorial.Editar usuário": "Edytuj użytkownika",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente a Loja a qual ele faz parte.": "Aby dokończyć rejestrację użytkownika, należy otworzyć menu boczne, kliknąć Edytuj i uzupełnić informacje dotyczące Sklepu, do którego należy.",
    "tutorial.Salvar edições de um equipamento": "Zapisywanie zmian na urządzeniu",
    "tutorial.Depois de completar o registro de seus equipamentos, clique em Salvar.": "Po zakończeniu rejestracji swojego sprzętu kliknij Zapisz.",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em uma loja, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Klikając na linię sprzętu zarejestrowanego w sklepie, możesz zobaczyć więcej statystyk i informacji na jego temat, a także możesz poprosić o usługę lub uruchomić aktualizacje.",
    "tutorial.Para visualizar mais informações sobre a loja, como localização e receitas mais preparadas, clique sobre a linha da unidade que deseja analisar.": "Aby zobaczyć więcej informacji o sklepie, takich jak lokalizacja i najczęściej przygotowywane przepisy, kliknij w wiersz jednostki, którą chcesz przeanalizować.",
    "tutorial.Criar receita": "Utwórz przepis",
    "tutorial.No cookbook você pode criar suas próprias receitas! Clique em Criar Receita e preencha todas as informações necessárias.": "W książce kucharskiej możesz tworzyć własne przepisy! Kliknij opcję Utwórz przepis i podaj wszystkie niezbędne informacje.",
    "tutorial.Editar ou excluir um usuário": "Edytuj lub usuń użytkownika",
    "tutorial.Para excluir ou editar as informações de um usuário já cadastrado, clique no menu lateral e selecione a opção desejada.": "Aby usunąć lub edytować dane już zarejestrowanego użytkownika, kliknij menu boczne i wybierz żądaną opcję.",
    "tutorial.Gerenciamento de usuários": "Zarządzanie użytkownikami",
    "tutorial.Aqui você pode gerenciar seus usuários com mais facilidade. Ao clicar em + Usuário, você poderá adicionar um novo usuário ou criar um novo tipo de usuário.": "Tutaj możesz łatwiej zarządzać swoimi użytkownikami. Klikając + Użytkownik, możesz dodać nowego użytkownika lub utworzyć nowy typ użytkownika.",
    "tutorial.Suas receitas": "Twoje przepisy",
    "tutorial.Pelo cookbook você também encontra suas próprias receitas, além de poder criá-las! Clique em Receitas Salvas para visualizar as receitas que foram salvas do cookbook da Prática e também as criadas por você.": "Dzięki książce kucharskiej znajdziesz także własne przepisy, a także możliwość ich tworzenia! Kliknij opcję Zapisane przepisy, aby wyświetlić przepisy zapisane z Praktycznej książki kucharskiej, a także te utworzone przez Ciebie.",
    "tutorial.Filtros do Cookbook": "Filtry do książek kucharskich",
    "tutorial.Para facilitar a navegação pelas receitas, você pode aplicar filtros a elas. Basta clicar no filtro desejado e escolher uma de suas opções.": "Aby ułatwić przeglądanie przepisów, możesz zastosować do nich filtry. Wystarczy kliknąć żądany filtr i wybrać jedną z jego opcji.",
    "tutorial.Cookbook": "Książka kucharska",
    "tutorial.No cookbook, em Receitas Disponíveis, você encontrará as receitas criadas pelos Chefs da Prática e poderá utilizá-las sempre que quiser.": "W książce kucharskiej, w zakładce Dostępne przepisy, znajdziesz przepisy stworzone przez praktykujących szefów kuchni i możesz z nich korzystać, kiedy tylko chcesz.",
    "tutorial.Atualizar equipamentos": "Aktualizuj sprzęt",
    "tutorial.Para enviar uma atualização a seus equipamentos, clique em Atualizar Equipamentos, selecione os modelos que deseja atualizar e a forma como deseja realizar essa atualização - via wi-fi ou pen drive. Depois disso, clique em Confirmar.": "Aby wysłać aktualizację do swojego sprzętu, kliknij Aktualizuj sprzęt, wybierz modele, które chcesz zaktualizować i sposób, w jaki chcesz przeprowadzić tę aktualizację - przez Wi-Fi lub pendrive. Następnie kliknij Potwierdź.",
    "tutorial.Adicionar equipamento": "Dodaj sprzęt",
    "tutorial.Para adicionar um equipamento à sua conta, clique em Adicionar Equipamento e preencha as informações do modal. Depois disso, clique em Salvar.": "Aby dodać sprzęt do swojego konta, kliknij Dodaj sprzęt i podaj informacje modalne. Następnie kliknij Zapisz.",
    "tutorial.Editar equipamento": "Edytuj sprzęt",
    "tutorial.Para desvincular um equipamento ou editar suas informações, clique no menu lateral e selecione a opção desejada.": "Aby odłączyć urządzenie lub edytować jego informacje, kliknij menu boczne i wybierz żądaną opcję.",
    "tutorial.Seus equipamentos": "Twój sprzęt",
    "tutorial.Aqui você visualizará todos os equipamentos cadastrados em sua plataforma.": "Tutaj zobaczysz cały sprzęt zarejestrowany na Twojej platformie.",
    "tutorial.Restaurar arquivos": "Przywróć pliki",
    "tutorial.Para restaurar um arquivo excluído ou deletá-lo permanentemente, clique no menu lateral e selecione a opção desejada.": "Aby przywrócić usunięty plik lub trwale go usunąć, kliknij menu boczne i wybierz żądaną opcję.",
    "tutorial.Lixeira": "Kosz",
    "tutorial.Os menus excluídos serão mantidos na Lixeira por 30 dias. Depois desse prazo serão excluídos permanentemente.": "Usunięte menu będą przechowywane w Koszu przez 30 dni. Po tym okresie zostaną one trwale usunięte.",
    "tutorial.Salvar edições de usuário": "Zapisz zmiany użytkownika",
    "tutorial.Depois de completar o cadastro dos usuários, clique em Salvar.": "Po zakończeniu rejestracji użytkownika kliknij Zapisz.",
    "tutorial.Criar menu": "Utwórz menu",
    "tutorial.Para criar e personalizar um menu, clique em Criar Menu e preencha as informações necessárias no modal. Você pode editar um menu a qualquer momento.": "Aby utworzyć i dostosować menu, kliknij opcję Utwórz menu i podaj niezbędne informacje w modalu. Możesz edytować menu w dowolnym momencie.",
    "tutorial.Importar menu": "Importuj menu",
    "tutorial.Para importar um menu, clique em Importar Menu e selecione o modelo e versão do equipamento.": "Aby zaimportować menu, kliknij opcję Importuj menu i wybierz model oraz wersję sprzętu.",
    "tutorial.Criar grupo": "Stworzyć grupę",
    "tutorial.Para organizar suas receitas em grupos, clique em Criar Grupo e preencha as informações necessárias no modal.": "Aby uporządkować przepisy w grupy, kliknij opcję Utwórz grupę i wprowadź niezbędne informacje w formularzu.",
    "tutorial.Tipos de usuário": "Typy użytkowników",
    "tutorial.Agora você também pode criar tipos de usuários com permissões específicas para atender melhor a sua organização.": "Możesz teraz tworzyć typy użytkowników z określonymi uprawnieniami, aby lepiej służyć Twojej organizacji.",
    "tutorial.Gerenciamento de contas": "Zarządzanie kontem",
    "tutorial.Se caso outra pessoa da sua organização também migrou a conta principal da empresa, aqui você pode unir as duas contas.": "Jeśli inna osoba w Twojej organizacji również przeprowadziła migrację swojego głównego konta firmowego, w tym miejscu możesz połączyć oba konta.",
    "tutorial.Para completar o registro de seus equipamentos, abra o menu lateral, clique em Editar e preencha as informações de Grupo.": "Aby dokończyć rejestrację swojego sprzętu, otwórz menu boczne, kliknij Edytuj i uzupełnij informacje o Grupie.",
    "tutorial.Para completar o cadastro dos usuários, abra o menu lateral, clique em Editar e preencha a informação referente ao Grupo de equipamentos a qual ele faz parte.": "Aby dokończyć rejestrację użytkownika należy otworzyć menu boczne, kliknąć Edytuj i uzupełnić informacje dotyczące Grupy Sprzętu, do której należy.",
    "tutorial.Grupos de equipamentos": "Grupy wyposażenia",
    "tutorial.Na aba Grupos você pode visualizar os grupos cadastrados e os equipamentos distribuídos entre eles. Além disso, é possível cadastrar novos grupos e editar os já cadastrados.": "W zakładce Grupy możesz przeglądać zarejestrowane grupy oraz rozdzielony pomiędzy nimi sprzęt. Ponadto możliwa jest rejestracja nowych grup oraz edycja już zarejestrowanych.",
    "tutorial.Detalhes de grupos": "Szczegóły grupy",
    "tutorial.Para visualizar mais informações sobre o grupo, como localização e receitas mais preparadas, clique sobre a linha que deseja analisar.": "Aby wyświetlić więcej informacji o grupie, takich jak lokalizacja i najczęściej przygotowane przepisy, kliknij na wiersz, który chcesz przeanalizować.",
    "tutorial.Equipamentos em grupos": "Sprzęt w grupach",
    "tutorial.Ao clicar sobre a linha de um equipamento cadastrado em um grupo, você pode visualizar mais estatísticas e informações sobre ele, além de poder solicitar um atendimento ou disparar atualizações.": "Klikając linię urządzenia zarejestrowanego w grupie, możesz wyświetlić więcej statystyk i informacji na jego temat, a także możesz poprosić o usługę lub uruchomić aktualizacje.",
    "tutorial.Grupos": "Grupy",
    "tutorial.Atualização e cache": "Aktualizuj i buforuj",
    "tutorial.Para atualizar a plataforma e corrigir eventuais problemas de cache, clique aqui.": "Aby zaktualizować platformę i rozwiązać wszelkie problemy z pamięcią podręczną, kliknij tutaj.",
    "tutorial.Dados dos equipamentos": "Dane urządzeń",
    "tutorial.Ao clicar sobre a linha de um equipamento, você pode visualizar mais estatísticas e informações sobre ele, como as receitas mais preparadas, limpezas realizadas e outros dados relevantes.": "Klikając na wiersz urządzenia, możesz wyświetlić więcej statystyk i informacji o nim, takich jak najczęściej przygotowane przepisy, wykonane czyszczenia i inne istotne dane.",
    "tutorial.Para alterar o idioma de sua plataforma, clique em Idioma e selecione a linguagem desejada.": "Aby zmienić język swojej platformy, kliknij Język i wybierz żądany język.",
    "tutorial.Alterar idioma": "Zmień język",
    "tutorial.Para editar ou configurar sua conta, clique em seu nome e selecione a opção desejada.": "Aby edytować lub skonfigurować swoje konto, kliknij swoje imię i nazwisko i wybierz żądaną opcję.",
    "tutorial.Editar conta": "Edytuj konto",
    "tutorial.Total de Usuários Cadastrados em sua conta.": "Całkowita liczba użytkowników zarejestrowanych na Twoim koncie.",
    "tutorial.Home": "Strona główna",
    "tutorial.Localização de seus equipamentos, sendo possível expandir o mapa para melhor visualização;": "Lokalizacja Twojego sprzętu, umożliwiająca poszerzenie mapy w celu lepszego przeglądania;",
    "tutorial.Atualizações referentes a versão dos equipamentos vinculados a sua conta;": "Aktualizacje dotyczące wersji sprzętu powiązanego z Twoim kontem;",
    "tutorial.Total de Receitas Cadastradas;": "Całkowity zarejestrowany dochód;",
    "tutorial.Menus Disponíveis;": "Dostępne menu;",
    "tutorial.Esta é a sua home! Aqui você encontra um resumo geral sobre sua conta, como quantidade de Equipamentos Vinculados;": "To jest Twoja strona główna! Tutaj znajdziesz ogólne podsumowanie swojego konta, takie jak liczba połączonego sprzętu;",
    "tutorial.Clique nas abas do menu para explorar a plataforma e conhecer tudo o que ela pode oferecer.": "Kliknij karty menu, aby poznać platformę i odkryć wszystko, co ma do zaoferowania.",
    "tutorial.Exportar menu": "Eksportuj menu",
    "tutorial.Depois de personalizar seu menu, exporte-o para seus equipamentos pela internet ou por pen drive.": "Po dostosowaniu menu wyeksportuj je na swoje urządzenia przez internet lub pendrive.",
    "tutorial.Na aba Localização você pode visualizar os endereços já cadastrados, além de criar localizações precisas para vincular seus equipamentos a elas.": "Na karcie Lokalizacja możesz przeglądać już zarejestrowane adresy, a także tworzyć dokładne lokalizacje, aby powiązać z nimi swój sprzęt.",
    "tutorial.Localização": "Lokalizacja",

    //RECOVER ACCOUNT
    "recoverAccount.Para prosseguir, selecione a categoria na qual você se encaixa:": "Aby kontynuować, wybierz kategorię, do której pasujesz:",
    "recoverAccount.Deseja acessar a plataforma como DISTRIBUIDOR? Crie sua conta clicando em EMPRESA e entre em contato conosco para liberarmos os demais acessos.": "Czy chcesz uzyskać dostęp do platformy jako DYSTRYBUTOR? Utwórz swoje konto, klikając na FIRMA i skontaktuj się z nami, aby odblokować inne dostępy.",
    "recoverAccount.A qual organização você pertence": "Do której organizacji należysz?",

    //RECOVER PASSWORD
    "recoverPassword.E-mail para recuperação de senha": "E-mail do odzyskiwania hasła",
    "recoverPassword.Informe o e-mail cadastrado na plataforma": "Podaj e-mail zarejestrowany na platformie",
    "recoverPassword.E-mail inválido. Por favor, verifique": "Nieprawidłowy adres e-mail. Proszę sprawdź",
    "recoverPassword.RECUPERAR SENHA": "ODZYSKAJ HASŁO",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL COM O LINK DE RECUPERAÇÃO DE SENHA": "WKRÓTCE OTRZYMASZ E-MAIL Z LINKIEM DO ODZYSKANIA HASŁA",
    "recoverPassword.Login": "Zaloguj się",
    "recoverPassword.Informe seu e-mail": "Podaj swój adres e-mail",
    "recoverPassword.Crie uma nova senha": "Utwórz nowe hasło",
    "recoverPassword.O campo senha deve conter no mínimo 6 caracteres": "Pole hasła musi mieć co najmniej 6 znaków",
    "recoverPassword.Confirme sua senha": "Potwierdź hasło",
    "recoverPassword.As senhas devem ser iguais": "Hasła muszą być zgodne",
    "recoverPassword.ENTRAR": "WPROWADŹ",
    "recoverPassword.EM BREVE VOCÊ RECEBERÁ UM E-MAIL PARA CONFIRMAR SUA IDENTIDADE": "WKRÓTCE OTRZYMASZ E-MAIL Z POTWIERDZENIEM TWOJEJ TOŻSAMOŚCI",
    "recoverPassword.CONTINUAR": "KONTYNUUJ",
    "recoverPassword.CONFIRMAR": "POTWIERDŹ",

    // EXPORT
    "export.Limpezas": "Czyszczenia",
    "export.Eventos": "Wydarzenia",
    "export.Lista de receitas preparadas": "Lista przygotowanych przepisów",
    "export.Lista de limpezas realizadas": "Lista przeprowadzonych czyszczeń",
    "export.Lista de eventos ocorridos": "Lista występujących zdarzeń",
    "export.Exportar dados": "Eksportuj dane",
    "export.Informações do equipamento": "Informacje o urządzeniu",
    "export.Dados exportados com sucesso!": "Dane zostały pomyślnie wyeksportowane!",
    "export.Não foi possível exportar os dados. Por favor, tente novamente mais tarde.": "Nie można wyeksportować danych. Proszę spróbować ponownie później.",
    "export.Detalhes do menu": "Szczegóły menu",
    "export.Detalhes de receitas": "Szczegóły przepisów",
    "export.Detalhes e Passos": "Szczegóły i Kroki",
    "export.Exportando dados. Em breve o arquivo estará disponível!": "Eksportowanie danych. Plik będzie wkrótce dostępny!",
    "export.Resumo dos passos": "Podsumowanie kroków",
    "export.salvar imagem": "zapisz obraz"
  },
};

export default pl;
