import styled from 'styled-components';
import * as DesignSystem from '../../../assets/styles/StyleTypes';

export const DivSwitch = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 15px 0px;

`;

export const DivText = styled.div`
  display: block;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0px 15px 0px;

  p {
    font-family: Roboto, sans-serif;
    font-weight: normal;
    color: ${(props) => props.theme.colors.textSelect};
    font-size: 10px;
  }
`;

export const Warning = styled.p`
    font-family: Roboto, sans-serif;
    font-weight: normal;
    color: ${(props) => props.theme.colors.textPrimary};
    font-size: 13px;
    text-align: justify;

    &#developerToken {
        letter-spacing: 0.26px;
        line-height: 18px;
    }
`;


export const DivRange = styled.div`
  display: flex;
  align-items: center;
`;

export const Label = styled.label`
  margin: 0;
`;

export const DivInputLine = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0px 0px 20px 0px;
    gap: 5px; 
`;

export const DivToken = styled.div`
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
    > div {
        width: 50%;
    }
    > p {
        padding-top: 20px;
        width: 50%;
    }
`;

export const Input = styled.input`
    border: none;
    height: 40px;
    width: 100%;
    cursor: pointer;
    background: none;
    outline: none;
`;

export const DivInput = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 20px; 

    &.token {
        align-items: center;
        background-color: ${props => props.theme.colors.backgroundInput};
        border-radius: ${DesignSystem.BorderRadius.SM};
        padding: 0 5px;
        cursor: pointer;

        svg {
            color: ${(props) => props.theme.colors.backgroundBox};
            font-size: 30px;
        }
    }
`;

export const Title = styled.div`
    width: auto;
    font-size: 12px;
    margin-top: 10px;
    margin-Bottom: 5px;
    flex: left;
    font-weight: 600;
    font-family: Roboto, sans-serif;
`;

export const Div = styled.div`
    display: flex;
    margin-bottom: 20px; 
    @media screen and (max-width: 700px) {
        flex-direction: column;
    }
    &.options {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const Div2 = styled.div`
    display: flex;
    margin-bottom: 20px;
`;

export const Name = styled.div`
    display: block;
    width: 380px;
    height: 64px;
    margin-right: 20px;

    @media screen and (max-width: 700px) {
        margin-right: 10px;
        min-width: 150px;
        width: 100%;
    }
`;

export const Phone = styled.div`
/*
    display: block;
    width: 210px;
    height: 64px;
    */
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
    .PhoneInput{                                                                                                         
        display: flex;
        justify-content: center;
        flex-direction: row;                                                                                                                                                                                                                                                                                  
        align-items: center;
    }
    .PhoneInputInput{
        height: 36px; 
        width: 212px;
        padding-left: 10px;
        border: none;
        border-bottom-right-radius: 6px;
        border-top-right-radius: 6px;
        box-shadow: 0 0 0 0;
        outline: 0;
        color: ${(props) => props.theme.colors.textPrimary};
        background-color: ${(props) => props.theme.colors.backgroundInput};
        @media screen and (max-width: 700px) {
            padding-left: 5px;
            min-width: 700px;
            width: 100%;
        }
        ::placeholder {
        color: ${(props) => props.theme.colors.backgroundInput};;
        font-family: Roboto, sans-serif;
        }
    }
    .PhoneInputCountry{
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
        background-color: ${(props) => props.theme.colors.backgroundInput};
        padding: 0 8px;
        height: 39px;
        border-bottom-left-radius: 6px;
        border-top-left-radius: 6px;
        justify-content: center;
    }
    .PhoneInputCountryIconImg{
        height: 26px;
        width: 26px;
        border-radius: 100%;
        object-fit: cover;
        color:  ${(props) => props.theme.colors.textTitle};;
        @media screen and (max-width: 700px) {
            height: 22px;
            width: 22px;
        }
    }
    .PhoneInputCountryIcon{
        height: 26px;
        width: 26px;
        @media screen and (max-width: 700px) {
            height: 22px;
            width: 22px;
        }
    }
    .PhoneInputCountrySelect{
        width: 20px;
        background: ${(props) => props.theme.colors.backgroundInput};
        border: none;
        color: ${(props) => props.theme.colors.textPrimary};
        cursor: pointer;
        box-shadow: 0 0 0 0;
        outline: 0;
        @media screen and (max-width: 700px) {
            width: 18px;
        }
    }
`;

export const Email = styled.div`
    display: block;
    width: 100%;
`;

export const Icon = styled.div`
    display: flex;
    align-items: center;
    gap: 10px;

    svg{
        color: ${(props) => props.theme.colors.textTitle};
        width: 20px;
        height: 20px;
        cursor: pointer;
    }
`;

export const Image = styled.img`
    width: 30px;
    height: auto;
    cursor: pointer;
`;

export const DivOption = styled.div`
    display: flex;
    height: auto;
    align-items: center;
    gap: 5px;

    svg{
        font-size: 18px;
        color: ${(props) => props.theme.colors.textTitle};
    }

    p {
    margin: 0px;
    text-align: left;
    letter-spacing: 0.15px;
    color: ${(props) => props.theme.colors.textTitle};
    opacity: 1;
    font-size: 12px;
    font-family: Roboto, sans-serif;
    font-weight: bold;
    cursor: pointer;
    padding-top: 5px;
    }
`;