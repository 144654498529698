import { db } from './db/db';
import { useLiveQuery } from 'dexie-react-hooks';
import { ToastContainer } from 'react-toastify';
import ProviderWrapper from './components/ProviderWrapper/index';
import AppRoutes from './routes';
import GlobalStyle from './assets/styles/styles';
import { useSelector } from 'react-redux';
import { RootState } from './store';

const GlobalStyleWrapper = () => {
  const showScroll = useSelector((state: RootState) => state.scroll.showScroll);
  return <GlobalStyle showScroll={showScroll} />;
};

const App = () => {
  const userDb = useLiveQuery(() => db.user.toArray());
  const companyDb = useLiveQuery(() => db.company.toArray());
  const userConfigsDb = useLiveQuery(() => db.userConfigs.toArray());
  const recipeDb = useLiveQuery(() => db.recipe.toArray());
  const cookbookDb = useLiveQuery(() => db.cookbook.toArray());
  const stepsDb = useLiveQuery(() => db.stepSpeedOven.toArray());
  const stepsCmaxDb = useLiveQuery(() => db.stepCombiOvenCMAX.toArray());

  return (
    <ProviderWrapper userConfigs={userConfigsDb}>
      <GlobalStyleWrapper />
      <AppRoutes
        user={userDb}
        userConfigs={userConfigsDb}
        company={companyDb}
        recipe={recipeDb}
        cookbook={cookbookDb}
        steps={stepsDb}
        stepsCmax={stepsCmaxDb}
      />
      <ToastContainer
        position='top-center'
        hideProgressBar
        closeButton={false}
      />
    </ProviderWrapper>
  );
};
export default App;
